import React from "react";
import styled from "styled-components";

function StrongEcosystem() {
    const items = [
        {
            id: 1,
            title: "Education",
            description:
                " To bring about a strong, job-oriented education system focused on practical knowledge.",
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/education.svg",
        },
        {
            id: 2,
            title: "Technology",
            description:
                " We live in the world of technology, and it's necessary to make use of the most advanced technologies in all fields, to make life easier.",
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/technology.svg",
        },
        {
            id: 3,
            title: "Jobs",
            description:
                "Jobs are an integral part of a stable economy. Hence, we should create more jobs and make sure everyone has one.",
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/jobs.svg",
        },

        {
            id: 4,
            title: "Entrepreneurship",
            description:
                "We need more innovative entrepreneurs and new startups to strengthen the economy and facilitate its growth.",
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/entrepreneurship.svg",
        },
    ];

    return (
        <Container>
            <Cover className="wrapper">
                <LeftSection>
                    <Title>Our areas of interest</Title>
                </LeftSection>
                <RightSection>
                    {items.map((data) => (
                        <Card key={data.id}>
                            <Icon>
                                <img src={data.icon} alt="Image" />
                            </Icon>
                            <Content>
                                <CardTitle>{data.title}</CardTitle>
                                <CardDescription>
                                    {data.description}
                                </CardDescription>
                            </Content>
                        </Card>
                    ))}
                </RightSection>
            </Cover>
        </Container>
    );
}

export default StrongEcosystem;
const Container = styled.div`
    padding: 130px 0;
    background-color: #f9f9f9;
    @media all and (max-width: 768px) {
        padding: 100px 0;
    }
    @media all and (max-width: 640px) {
        padding: 80px 0;
    }
    @media all and (max-width: 360px) {
        padding: 70px 0;
    }
`;
const Cover = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    @media all and (max-width: 1280px) {
        grid-template-columns: 1fr;
    }
`;
const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const Title = styled.h2`
    font-size: 34px;
    font-family: gordita_medium;
    @media all and (max-width: 1280px) {
        text-align: center;
        margin: 0 auto;
        margin-bottom: 70px;
        br {
            display: none;
        }
    }
    @media all and (max-width: 768px) {
        font-size: 30px;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
        margin-bottom: 50px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
`;

const RightSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    @media all and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
    @media all and (max-width: 360px) {
        grid-gap: 30px;
    }
`;
const Card = styled.div`
    display: flex;
    justify-content: flex-start;
    @media all and (max-width: 360px) {
        display: grid;
        grid-gap: 20px;
    }
`;
const Icon = styled.span`
    display: block;
    width: 50px;
    min-width: 50px;
    margin-right: 20px;
    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 640px) {
        width: 45px;
        min-width: 45px;
    }
`;
const Content = styled.div``;

const CardTitle = styled.h4`
    font-size: 20px;
    color: #545454;
    font-family: gordita_medium;
    margin-bottom: 10px;
    @media all and (max-width: 768px) {
        font-size: 18px;
    }
    @media all and (max-width: 640px) {
        font-size: 16px;
    }
`;
const CardDescription = styled.p`
    font-size: 16px;
    @media all and (max-width: 640px) {
        font-size: 14px;
    }
`;
