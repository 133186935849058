import React, { useState } from "react";
import styled from "styled-components";
import VideoModal from "../includes/modal/VideoModal";

export default function TalropVillage() {
    const [isModal, setModal] = useState(false);

    const datas = [
        {
            id: 1,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/upcoming/Red.svg",
            title: "Kmj, Kannur, Kannur Assembly Constituency",
            description:
                "An American model mini Silicon Valley project from Talrop, on a mission, to design and develop 140 tech startups from Kerala by 2025.",
        },
        {
            id: 2,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/upcoming/green.svg",
            title: "Christ, Thrissur, Irinjalakuda Assembly Constituency",
            description:
                "Talrop Village provides an incredible infrastructure facility for all 140 startups with amenities including office spaces, malls, game arenas, accommodation, etc to increase productivity and ensure the physical and mental well-being of everyone working on their startups.",
        },
    ];

    return (
        <MainContainer className="wrapper">
            <VideoModal isModal={isModal} setModal={setModal} />
            <Container id="talrop-village">
                <Left>
                    <Heading>
                        Our Upcoming <br />
                        Talrop Village
                    </Heading>
                    <BottomContainer>
                        {datas.map((data) => (
                            <Card key={data.id}>
                                <IconContainer types={data.title}>
                                    <Icon src={data.icon} alt="Icon" />
                                </IconContainer>
                                <Description>{data.description}</Description>
                            </Card>
                        ))}
                    </BottomContainer>
                </Left>
                <Right>
                    <Thumnail
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Thump+video-min.png"
                        alt=""
                    />
                    <PlayerIconContainer onClick={() => setModal(true)}>
                        <PlayIcon
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/upcoming/play-button.svg"
                            alt="Icon"
                        />
                    </PlayerIconContainer>
                </Right>
            </Container>
        </MainContainer>
    );
}
const MainContainer = styled.div`
    padding: 97px 55px 90px 55px;
    background: #f9f9f9;
    margin-bottom: 50px;
    border-radius: 7px;
    @media all and (max-width: 1280px) {
        padding: 65px 38px 35px 38px;
    }
    @media all and (max-width: 640px) {
        padding: 42px 19px 35px 19px;
    }
    @media all and (max-width: 540px) {
        margin-bottom: 27px;
    }
`;
const Container = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    @media all and (max-width: 1280px) {
        justify-content: space-between;
    }
    @media all and (max-width: 980px) {
        flex-direction: column;
    }
`;
const Left = styled.div`
    width: 37%;
    @media all and (max-width: 1280px) {
        width: 45%;
    }
    @media all and (max-width: 980px) {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
`;
const Right = styled.div`
    width: 55%;
    border-radius: 12px;
    /* background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Thump+video-min.png")
        no-repeat;
    background-size: 100% 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 361px; */
    position: relative;
    @media all and (max-width: 1280px) {
        /* height: 321px; */
        width: 51%;
    }
    @media all and (max-width: 980px) {
        width: 100%;
        /* height: 310px; */
        margin-top: 40px;
    }
    @media all and (max-width: 540px) {
        /* height: 211px; */
    }
    @media all and (max-width: 420px) {
        /* height: 175px; */
    }
`;
const PlayerIconContainer = styled.div`
    width: 80px;
    cursor: pointer;
    position: absolute;
    @media all and (max-width: 540px) {
        width: 45px;
    }
`;
const PlayIcon = styled.img`
    width: 100%;
    display: block;
`;

const Heading = styled.h2`
    font-size: 32px;
    font-family: gordita_medium;
    color: #212121;
    position: relative;
    display: inline-block;
    margin: 0px auto 50px;
    margin-bottom: 54px;
    letter-spacing: 0.41px;
    &::before {
        content: "";
        position: absolute;
        width: 233px;
        bottom: -1px;
        height: 7px;
        background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/upcoming/line.svg")
            no-repeat;
        background-size: 100% 100%;
        display: block;
        @media all and (max-width: 980px) {
            width: 198px;
        }
        @media all and (max-width: 480px) {
            width: 150px;
        }
    }
    @media all and (max-width: 1200px) {
        font-size: 27px;
        margin-bottom: 42px;
    }
    @media all and (max-width: 980px) {
        text-align: center;
    }
    @media all and (max-width: 640px) {
        margin-bottom: 27px;
    }
    @media all and (max-width: 480px) {
        font-size: 20px;
    }
`;
const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`;
const Card = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 23px;
    position: relative;
    &:last-child {
        margin-bottom: 0px;
    }
    @media all and (max-width: 480px) {
        flex-direction: column;
    }
`;
const IconContainer = styled.div`
    width: 30px;
    height: 30px;
    margin-right: 18px;
    @media all and (max-width: 480px) {
        margin-right: 0px;
        margin-bottom: 15px;
    }
`;
const Icon = styled.img`
    width: 100%;
    display: block;
`;

const Description = styled.p`
    letter-spacing: 0px;
    color: #7e7e7e;
    line-height: 1.8;
    font-size: 18px;
    width: 90%;
`;
const Thumnail = styled.img`
    /* position: absolute;
    top: 0;
    left: 0; */
    display: block;
    width: 100%;
    border-radius: 12px;
`;
