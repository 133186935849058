import React, { useEffect } from "react";
import DettSpotlight from "./new-landing/DettSpotlight";
import DiuuSpotlight from "./new-landing/DiuuSpotlight";
import GroliusSpotlight from "./new-landing/GroliusSpotlight";
import Header from "./new-landing/Header";
import KinfigSpotlight from "./new-landing/KinfigSpotlight";
import MatineeSpotlight from "./new-landing/MatineeSpotlight";
import StartupsSpotlight from "./new-landing/StartupsSpotlight";
import SteypSpotlight from "./new-landing/SteypSpotlight";
import StickonSpotlight from "./new-landing/StickonSpotlight";
import TegainSpotlight from "./new-landing/TegainSpotlight";
import WiseTalkiesSpotlight from "./new-landing/WiseTalkiesSpotlight";
import YkippSpotlight from "./new-landing/YkippSpotlight";
import Footer from "./new-landing/Footer";

function NewStartupsPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Header />
            <StartupsSpotlight />
            <SteypSpotlight />
            <TegainSpotlight />
            <StickonSpotlight />
            <MatineeSpotlight />
            <DettSpotlight />
            <YkippSpotlight />
            <KinfigSpotlight />
            <WiseTalkiesSpotlight />
            <DiuuSpotlight />
            <GroliusSpotlight />
            <Footer />
        </>
    );
}

export default NewStartupsPage;
