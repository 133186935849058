import React from "react";
import styled from "styled-components";

export default function InternalSquad() {
    const internalSquadSystems = [
        {
            id: 1,
            title: "Business Development Squad",
            bg_color: "rgba(15, 166, 116, 0.1)",
            color: "#0483BC",
            description:
                "Heads the responsibility of building relations and networking for the growth of the business.",
        },
        {
            id: 2,
            title: "Community Squad",
            bg_color: "rgba(238, 199, 73, 0.1)",
            color: "#EEC749",
            description:
                "In charge of building community, introducing new community projects, and ensuring the smooth execution of existing projects.",
        },
        {
            id: 3,
            title: "Marketing Squad",
            bg_color: "rgba(138, 98, 214, 0.1)",
            color: "#8A62D6",
            description:
                "In charge of taking products and services of Talrop's startups to the target audience and generating quality leads.",
        },
        {
            id: 4,
            title: "Sales Squad",
            bg_color: "#0fa6743b",
            color: "#0FA674",
            description:
                "In charge of executing the sales of products and services of Talrop's startups.",
        },

        {
            id: 5,
            title: "Branding Squad",
            bg_color: "rgba(151, 192, 61, 0.1)",
            color: "#97C03D",
            description:
                "Responsible for carrying out activities to create a distinguished place for Talrop and it's startups as a brand in consumer's minds.",
        },
        {
            id: 6,
            title: "Media & Promotion Squad",
            bg_color: "rgba(15, 158, 167, 0.1)",
            color: "#0F9EA7",
            description:
                "Responsible for creating and curating entire content required for Talrop and it's startups.",
        },
        {
            id: 7,
            title: "Cyber Squad",
            bg_color: "rgba(0, 177, 209, 0.1)",
            color: "#00B1D1",
            description:
                "In charge of tech platform development and maintenance of startups .",
        },

        {
            id: 8,
            title: "Research Squad",
            bg_color: "rgba(205, 103, 153, 0.1)",
            color: "#CD6799",
            description:
                "Carries out studies and research in various fields to support the growth of the company.",
        },
        {
            id: 9,
            title: "Administration Squad",
            bg_color: "rgba(123, 171, 244, 0.1)",
            color: "#7BABF4",
            description:
                "To handle the administrative aspects and to provide all the administrative services to Talrop's startups.",
        },
        {
            id: 10,
            title: "HR Squad",
            bg_color: "rgba(15, 166, 116, 0.1)",
            color: "#0FA674",
            description:
                "In charge of entire human resource management from hiring highly skilled employees to maintaining employee relations.",
        },
        {
            id: 11,
            title: "Finance Squad",
            bg_color: "rgba(229, 188, 38, 0.1)",
            color: "#E5BC26",
            description:
                "In charge of all financial matters of Talrop's startups from budgeting to risk management.",
        },

        {
            id: 12,
            title: "Legal Squad",
            bg_color: "rgba(237, 249, 255, 1)",
            color: "#0483BC",
            description:
                "Deals with all the legal matters and assists startups in handling them.",
        },
    ];
    return (
        <Container>
            <section className="wrapper">
                <Head>
                    <b>Internal</b> Squad <span>System</span>
                </Head>
                <Description>
                    Talrop consists of a centralised squad system to build and
                    scale startups
                </Description>
                <Ul>
                    {internalSquadSystems.map((item) => (
                        <Li key={item.id} data-aos="fade-up">
                            <Count
                                style={{
                                    background: item.bg_color,
                                }}
                            >
                                <p
                                    style={{
                                        color: item.color,
                                    }}
                                >
                                    {item.id > 9 ? item.id : `0${item.id}`}
                                </p>
                            </Count>
                            <Details>
                                <Title>{item.title}</Title>
                                <SubDescription>
                                    {item.description}
                                </SubDescription>
                            </Details>
                        </Li>
                    ))}
                </Ul>
            </section>
        </Container>
    );
}
const Container = styled.section`
    padding: 75px 0;
    @media all and (max-width: 769px) {
        padding: 55px 0 0 0;
    }
    @media all and (max-width: 640px) {
        padding: 30px 0 0 0;
    }
`;
const Head = styled.h3`
    font-size: 40px;
    font-family: gordita_medium;
    color: #212121;
    text-align: center;
    margin-bottom: 20px;

    & span {
        position: relative;
    }

    & span::before {
        content: "";
        background: url(${"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/underline-green.svg"});
        background-repeat: no-repeat;
        position: absolute;
        background-size: 100%;
        width: 100%;
        left: 0;
        height: 15px;
        bottom: -10px;
        z-index: -1;
    }

    @media all and (max-width: 1400px) {
        font-size: 38px;
    }
    @media all and (max-width: 1280px) {
        font-size: 34px;
    }
    @media all and (max-width: 1080px) {
        font-size: 31px;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
    @media all and (max-width: 360px) {
        font-size: 20px;
    }
    b {
        color: #0bbe61;
        font-family: gordita_bold;
    }
`;
const Description = styled.p`
    font-size: 18px;
    text-align: center;
    margin-bottom: 50px;

    @media all and (max-width: 980px) {
        font-size: 17px;
    }
    @media all and (max-width: 768px) {
        font-size: 16px;
    }
`;
const Ul = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
`;
const Li = styled.div`
    width: 32%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;

    @media all and (max-width: 980px) {
        width: 48%;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const Count = styled.div`
    margin-right: 20px;
    /* background-color: #e0edf3; */
    min-width: 35px;
    min-height: 35px;
    width: 30px;
    height: 30px;
    border-radius: 2px;
    position: relative;

    & p {
        font-family: "gordita_bold";
        font-size: 22px;
        position: absolute;
        bottom: -8px;
        right: -8px;
    }
`;
const Details = styled.div`
    width: calc(100% - 50px);
    font-size: 23px;
    font-family: "gordita_medium";
`;
const Title = styled.h5`
    color: #545454;
    margin-bottom: 10px;
`;
const SubDescription = styled.p`
    color: #7e7e7e;
    font-size: 17px;

    @media all and (max-width: 980px) {
        font-size: 16px;
    }
    @media all and (max-width: 768px) {
        font-size: 15px;
    }
`;
