import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-scroll";
import styled from "styled-components";
import underline from "../../assets/images/new-landing/line.svg";

export default function Mammootty() {
    return (
        <Container>
            <InnerContainer>
                <TitleBox data-aos="fade-right">
                    <Middle>
                        Steyp's
                        <AmbassadorText> Brand Ambassador</AmbassadorText>
                    </Middle>
                    <Bottom>
                        Megastar
                        <HighlightedText> Mammootty</HighlightedText>
                    </Bottom>
                    <ButtonImg target="_blank" href="https://www.steyp.com/">
                        <img
                            src={
                                require("../../assets/images/new-landing/Steyp_button.svg")
                                    .default
                            }
                            alt="Steyp"
                        />
                    </ButtonImg>
                </TitleBox>
                <MammookaImgBox data-aos="fade-left">
                    <MammoottyImg
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/mammookka.png"
                        alt="Mammootty"
                    />
                </MammookaImgBox>
            </InnerContainer>
        </Container>
    );
}

const Container = styled.div`
    background-color: #1e1e1e;
`;
const InnerContainer = styled.div`
    max-width: 1325px;
    width: 85%;
    margin: 0 auto;
    position: relative;
    padding: 110px 0;
    display: flex;
    align-items: center;
    @media all and (max-width: 1080px) {
        width: 88%;
    }
    @media all and (max-width: 980px) {
        padding: 75px 0;
    }
    @media all and (max-width: 640px) {
        width: 94%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
        padding: 40px 0 40px 20px;
        display: block;
    }

    @media all and (max-width: 360px) {
        width: 100%;
    }
`;
const TitleBox = styled.div``;
const ButtonImg = styled.a`
    width: 35%;
    background: #313131;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    @media all and (max-width: 480px) {
        width: 20%;
    }
    @media all and (max-width: 360px) {
        width: 25%;
    }
`;
const Middle = styled.h2`
    font-family: "gordita_medium";
    font-size: 36px;
    color: #ffffff;

    @media all and (max-width: 980px) {
        font-size: 35px;
    }
    @media all and (max-width: 768px) {
        font-size: 28px;
    }
    @media all and (max-width: 640px) {
        font-size: 24px;
    }
    @media all and (max-width: 480px) {
        font-size: 21px;
    }
    @media all and (max-width: 420px) {
        font-size: 19px;
    }
`;
const AmbassadorText = styled.span`
    font-size: 34px;
    color: #0cbe62;
    font-family: "gordita_medium";

    @media all and (max-width: 980px) {
        font-size: 32px;
    }
    @media all and (max-width: 768px) {
        font-size: 24px;
    }
    @media all and (max-width: 640px) {
        font-size: 22px;
    }
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
    @media all and (max-width: 420px) {
        font-size: 17px;
    }
`;
const Bottom = styled.h2`
    font-size: 38px;
    color: #fff;
    margin-bottom: 20px;
    font-family: gordita_regular;
    @media all and (max-width: 980px) {
        font-size: 32px;
    }
    @media all and (max-width: 768px) {
        font-size: 24px;
    }
    @media all and (max-width: 640px) {
        font-size: 22px;
    }
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
    @media all and (max-width: 420px) {
        font-size: 17px;
    }
`;
const HighlightedText = styled.span`
    font-size: 42px;
    color: #fff;
    font-family: "gordita_medium";
    position: relative;
    &:after {
        content: "";
        position: absolute;
        background-image: url(${underline});
        background-repeat: no-repeat;
        bottom: -5px;
        left: 17px;
        height: 15px;
        width: 265px;
        z-index: -1;
        @media all and (max-width: 980px) {
            width: 215px;
        }
        @media all and (max-width: 768px) {
            width: 182px;
            bottom: -7px;
            left: 4px;
        }
        @media all and (max-width: 640px) {
            width: 160px;
            left: 2px;
        }
        @media all and (max-width: 480px) {
            width: 126px;
            bottom: -8px;
        }
        @media all and (max-width: 360px) {
            width: 123px;
            bottom: -8px;
            left: 4px;
        }
    }
    @media all and (max-width: 980px) {
        font-size: 35px;
    }
    @media all and (max-width: 768px) {
        font-size: 28px;
    }
    @media all and (max-width: 640px) {
        font-size: 24px;
    }
    @media all and (max-width: 480px) {
        font-size: 21px;
    }
    @media all and (max-width: 420px) {
        font-size: 19px;
    }
`;
const MammookaImgBox = styled.div`
    position: absolute;
    right: -23px;
    bottom: 0;
    width: 365px;

    @media all and (max-width: 980px) {
        width: 256px;
    }
    @media all and (max-width: 768px) {
        width: 218px;
    }
    @media all and (max-width: 640px) {
        width: 192px;
    }
    @media all and (max-width: 480px) {
        width: 142px;
        right: 7px;
    }
    @media all and (max-width: 420px) {
        width: 108px;
    }
    @media all and (max-width: 360px) {
        width: 95px;
    }
`;
const MammoottyImg = styled.img`
    display: block;
    width: 100%;
`;
