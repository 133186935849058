import React from "react";
import styled from "styled-components";

export default function OurTechies() {
	const datas = [
		{
			id: 3,
			icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Techies-park/KVUPS-min.png",
			title: "KVUP School, Vamanapuram Assembly Constituency",
			description:
				"  Pangode - Pazhavila Rd, Milma Distribution Society, Kallara, Kerala 695609",
			link: "https://g.page/talrop",
		},
		{
			id: 5,
			icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Techies-park/JNE-Malappuram-min.png",
			title: "Jamia Nadwiyya, Eranad Assembly Constituency",
			description: " Chembakuth, Edavanna, Kerala 676123",
			link: "https://g.page/talrop-techies-park-edavanna",
		},
		{
			id: 6,
			icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Techies-park/NACT-Kasaragod-min.png",
			title: "NA Model School, Kasaragod Assembly Constituency",
			description:
				"   Naimarmoola - Perumbala Kadavu Rd, Vidya Nagar, Chengala, Kerala 671121",
			link: "https://goo.gl/maps/7A7f14oHUGAcDrJPA",
		},
		{
			id: 2,
			icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/tegain-min.jpeg",
			title: "Christ College of Engineering, Irinjalakuda Constituency",
			description: "Christ Nagar, Irinjalakuda, Kerala 680125",
			link: "https://g.page/ChristEngineeringCollege",
		},
		{
			id: 4,
			icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-01-2022/LVHS-Techies-Parks.jpeg",
			title: "Lekshmi Vilasom High School, Nedumangad Assembly Constituency",
			description: "Pothencode, Kerala 695584",
			link: "https://goo.gl/maps/A3BpVeTSPoz1wC3d7",
		},
		{
			id: 1,
			icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Techies-park/KMJ-Kannur-min.png",
			title: "Naher College, KMJ, Kannur Assembly Constituency",
			description: "Naheer Arts & Science College Kanhirode.P O, Kannur - Mattannur Rd, Koodali, Kerala 670592",
			link: "https://goo.gl/maps/TwCHdcrmkcp3o4qn7",
		},
	];

	return (
		<MainContainer className="wrapper">
			<Container id="techies-park">
				<Heading>Talrop's Techies Parks</Heading>
				<SectionDescription>
					To build a strong startup ecosystem and accelerate startups,
					Talrop is establishing Techies Parks in all 140 Assembly
					constituencies of Kerala. 6 of them are already established
					and remaining 134 will be done by the end of 2024.
				</SectionDescription>
				<BottomContainer>
					{datas.map((data) => (
						<Card key={data.id}>
							<div>
								<IconContainer types={data.title}>
									<Icon src={data.icon} alt="Icon" />
								</IconContainer>
								<Title>{data.title}</Title>
							</div>
							{/* <Description>{data.description}</Description> */}
							<ButtonContainer>
								<Button href={data.link} target="_blank">
									<ShareIconContainer>
										<ShareIcon
											src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/techies/Direction.svg"
											alt="Icon"
										/>
									</ShareIconContainer>
									<p> Directions</p>
								</Button>
							</ButtonContainer>
						</Card>
					))}
				</BottomContainer>
			</Container>
		</MainContainer>
	);
}
const MainContainer = styled.div`
	padding: 120px 0px;
	@media all and (max-width: 768px) {
		padding: 60px 0px 55px;
	}
	@media all and (max-width: 640px) {
	}
`;
const Container = styled.div`
	/* @media all and (max-width: 768px) {
        padding-bottom: 45px;
    }
    @media all and (max-width: 640px) {
        padding-top: 50px;
        padding-bottom: 25px;
    } */
`;
const Heading = styled.h2`
	font-size: 34px;
	font-family: gordita_medium;
	color: #212121;
	text-align: center;
	margin: 0px auto;
	letter-spacing: 0.41px;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: -85px;
		left: 443px;
		width: 233px;
		height: 221px;
		background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/backgrounds-color.png")
			no-repeat;
		background-size: contain;
		display: block;
		z-index: -1;
		@media all and (max-width: 1280px) {
			left: 300px;
			width: 200px;
			height: 200px;
		}
		@media all and (max-width: 980px) {
			left: 234px;
			width: 174px;
			height: 180px;
			top: -60px;
		}
		@media all and (max-width: 768px) {
			left: 144px;
		}
		@media all and (max-width: 640px) {
			top: -44px;
			left: -42px;
			width: 237px;
			height: 131px;
		}
	}
	@media all and (max-width: 1200px) {
		font-size: 32px;
	}
	@media all and (max-width: 980px) {
		text-align: center;
	}
	@media all and (max-width: 768px) {
		font-size: 30px;
	}
	@media all and (max-width: 640px) {
		margin-bottom: 19px;
	}
	@media all and (max-width: 480px) {
		font-size: 24px;
	}
`;
const BottomContainer = styled.div`
	display: flex;
	/* align-items: center; */
	justify-content: center;
	flex-wrap: wrap;
	@media all and (max-width: 1390px) {
		justify-content: space-around;
	}
	@media all and (max-width: 1280px) {
		justify-content: space-between;
	}
	@media all and (max-width: 768px) {
		justify-content: space-between;
	}
	@media all and (max-width: 640px) {
		justify-content: center;
	}
`;
const Card = styled.div`
	background: #ffffff;
	border-radius: 10px;
	padding: 24px 20px 24px 20px;
	width: 25%;
	transition: all 0.4s ease-in-out;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* min-height: 364px; */
	margin-bottom: 40px;
	&:hover {
		background: #f9f9f9;
		border-radius: 10px;
		transition: all 0.4s ease-in-out;
		transform: translateY(-10px);
	}
	@media all and (max-width: 1390px) {
		width: 33%;
	}
	@media all and (max-width: 1280px) {
		width: 32%;
		/* min-height: 415px; */
	}
	@media all and (max-width: 980px) {
		width: 47%;
		/* min-height: 430px; */
	}
	@media all and (max-width: 768px) {
		width: 49%;
		padding: 22px 13px 22px 13px;
	}
	@media all and (max-width: 640px) {
		width: 100%;
		margin-bottom: 10px;
		min-height: unset;
		&:last-child {
			margin-bottom: 0;
		}
	}
	@media all and (max-width: 480px) {
		margin-bottom: 0px;
	}
`;
const IconContainer = styled.div``;
const Icon = styled.img`
	border-radius: 10px;
	width: 100%;
	display: block;
`;
const Title = styled.h3`
	font-family: "gordita_medium";
	margin-top: 18px;
	font-size: 16px;
	@media all and (max-width: 1280px) {
	}
`;
const Description = styled.p`
	letter-spacing: 0px;
	color: #7e7e7e;
	line-height: 1.5;
	margin-top: 7px;
	font-size: 15px;
	@media all and (max-width: 480px) {
		font-size: 14px;
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 27px;
	/* position: absolute; */
	width: 100%;
	bottom: 20px;
	@media all and (max-width: 640px) {
		position: relative;
		bottom: 0;
	}
	@media all and (max-width: 480px) {
		margin-top: 19px;
	}
`;
const Button = styled.a`
	cursor: pointer;
	width: 51%;
	padding: 7px 14px;
	background: #e5f5ef;
	border: 1px solid #1ada93;
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #0fa76f;
	font-size: 16px;
	p {
		color: #0fa76f;
		font-size: 16px;
		transform: translateY(3px);
	}
	@media all and (max-width: 340px) {
		width: 54%;
	}
`;
const ShareIconContainer = styled.div`
	margin-right: 7px;
	width: 20px;
	min-width: 20px;
	display: flex;
`;
const ShareIcon = styled.img`
	width: 100%;
	display: block;
`;

const SectionDescription = styled.p`
	letter-spacing: 0px;
	color: #8e8c8c;
	line-height: 1.5;
	margin-top: 10px;
	font-size: 16px;
	width: 70%;
	max-width: 700px;
	text-align: center;
	margin: 10px auto;
	margin-bottom: 54px;

	&.paragraph {
		text-align: left;
		font-size: 14px;
		width: 100%;
	}
	@media all and (max-width: 640px) {
		width: 100%;
		margin-bottom: 27px;
	}
`;
