import axios from "axios";
//-------------LOCAL----------------------
// export const manageConfig = axios.create({
// 	baseURL: "http://192.168.0.233:8000/api/v1/",
// });

//---------DEVELOPER URL-------------------------------------------
export const manageConfig = axios.create({
    baseURL:
        "https://api-71fad6fc-f8f6-4544-93f4-7f3ec9f14107.talrop.works/api/v1/",
});

//----------LIVE URL-----------------------------------------------
// export const manageConfig = axios.create({
//     baseURL: "https://api.talrop.com/api/v1/",
// });
