import React from "react";
import styled from "styled-components";

export default function EcosystemSpot() {
    return (
        <Container>
            <BgContainer>
                <section className="wrapper">
                    <Left data-aos="fade-right">
                        <Title>
                            The <b>Startup</b> <span>Ecosystem</span>
                        </Title>
                        <Description>
                            A strong startup ecosystem helps early stage
                            startups to build ideas into reality with the help
                            of a community of expertise bringing all assistance
                            together under one umbrella. An ecosystem helps and
                            creates an environment that is best suitable for
                            startups to grow and conquer greater heights.
                        </Description>
                    </Left>
                    <Right>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/ecosystem-spot.png"
                            alt="Ecosystem"
                        />
                    </Right>
                </section>
            </BgContainer>
        </Container>
    );
}
const Container = styled.section`
    padding: 115px 0 0 0;
    background-image: linear-gradient(#fdfffd, #f0f8ee);
    @media all and (max-width: 768px) {
        padding: 86px 0 0 0;
    }

    & .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
`;
const BgContainer = styled.div`
    padding: 62px 0 75px 0;
    background-image: url(${"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/ecosystem-spot.png"});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 50%;

    @media all and (max-width: 1280px) {
        padding: 50px 0 20px 0;
    }
    @media all and (max-width: 1080px) {
        background-size: 50%;
        padding: 50px 0 0px 0;
    }
    @media all and (max-width: 980px) {
        background-image: none;
    }
`;
const Left = styled.div`
    width: 50%;

    @media all and (max-width: 980px) {
        width: 100%;
        margin-bottom: 20px;
    }
`;
const Right = styled.div`
    width: 50%;
    display: none;

    @media all and (max-width: 980px) {
        display: block;
        margin: 0 auto;
        width: 75%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
const Title = styled.h1`
    font-size: 40px;
    font-family: gordita_medium;
    color: #212121;
    text-align: left;
    margin-bottom: 30px;

    & span {
        position: relative;
        z-index: 0;
    }

    & span::before {
        content: "";
        background: url(${"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/UnderLineDiu.png"});
        background-repeat: no-repeat;
        position: absolute;
        background-size: 100%;
        width: 100%;
        height: 15px;
        bottom: -15px;
        left: 0;
        z-index: -1;
    }

    @media all and (max-width: 1400px) {
        font-size: 38px;
    }
    @media all and (max-width: 1280px) {
        font-size: 34px;

        br {
            display: none;
        }
    }
    @media all and (max-width: 1080px) {
        font-size: 31px;
        br {
            display: block;
        }
    }
    @media all and (max-width: 980px) {
        text-align: center;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
    @media all and (max-width: 360px) {
        font-size: 20px;
    }
    b {
        color: #0bbe61;
        font-family: gordita_bold;
    }

    & br {
        @media all and (max-width: 460px) {
            display: none;
        }
    }
`;
const Description = styled.p`
    font-size: 18px;
    text-align: left;
    margin-bottom: 20px;

    @media all and (max-width: 980px) {
        font-size: 17px;
        text-align: center;
    }
    @media all and (max-width: 768px) {
        font-size: 14px;
    }
    @media all and (max-width: 1380px) {
        br {
            display: none;
        }
    }
`;
