import React, { useState } from "react";
import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";

const Footer = ({ isHome }) => {
    const programs = [
        { id: 1, name: "Founders Circle", link: "our-programs" },
        { id: 2, name: "Angel Investors Deck", link: "our-programs" },
        { id: 3, name: "Professionals Spot", link: "our-programs" },
        { id: 4, name: "Task Force", link: "our-programs" },
        { id: 5, name: "Startup Community", link: "our-programs" },
    ];
    const company = [
        { id: 1, name: "Techies Park", link: "techies-park" },
        { id: 2, name: "Cyber Squad", link: "cyber-squad" },
        { id: 2, name: "Startup Squad", link: "startup-squad" },
        { id: 2, name: "Commercial Squad", link: "commercial-squad" },
    ];

    return (
        <>
            <Cover>
                <Container className="wrapper">
                    <LeftSection>
                        <Logo>
                            <a href="/">
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/logo-gray.svg"
                                    alt="Logo"
                                />
                            </a>
                        </Logo>
                    </LeftSection>
                    <TechRight>
                        <ProgramSection>
                            <FooterTitle>Explore</FooterTitle>
                            <RouterLink to="/projects/technology-school/">
                                Technology School
                            </RouterLink>
                            {/* <RouterLink to="/startup-school">
                                Startup School
                            </RouterLink> */}
                            <PhoneLinks href="https://yia.ai/" target="_blank">
                                Yiaai
                            </PhoneLinks>
                            <PhoneLinks href="https://taid.in/" target="_blank">
                                TAID
                            </PhoneLinks>
                        </ProgramSection>
                        <ProgramSection className="second">
                            <FooterTitle>Contact</FooterTitle>
                            <PhoneLinks href="tel:+91 858 999 8016">
                                +91 858 9999 555
                            </PhoneLinks>
                            {/* <PhoneLinks href="tel:+91 730 602 3057">
                                +91 730 602 3057
                            </PhoneLinks> */}
                            <MailLinks href="mailto:hello@talrop.com">
                                hello@talrop.com
                            </MailLinks>
                        </ProgramSection>
                        <ProgramSection className="third">
                            <FooterTitle>Head office</FooterTitle>
                            <Address>
                                Talrop Private Limited,&nbsp; 3rd floor, Ardra
                                Building, No. 5 Maveli Nagar, Thrikkakkara
                                Pipeline Junction, Kochi, Kerala, India - 682033
                            </Address>
                        </ProgramSection>
                    </TechRight>
                </Container>
                <CopySect className="wrapper">
                    <h4>©2022 Talrop Private Limited</h4>
                    <SocialMedia>
                        <SocialLinks
                            target="_blank"
                            href="https://www.instagram.com/talropworld/"
                        >
                            <img
                                className="prime
                                "
                                src={
                                    "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/instagram-color.svg"
                                }
                                alt=""
                            />
                            <img
                                className="secondry"
                                src={
                                    "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/instagram.svg"
                                }
                                alt=""
                            />
                        </SocialLinks>
                        <SocialLinks
                            target="_blank"
                            href="https://www.facebook.com/talropworld/"
                        >
                            <img
                                className="prime"
                                src={
                                    "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/facebook-color.svg"
                                }
                                alt=""
                            />
                            <img
                                className="secondry"
                                src={
                                    "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/facebook.svg"
                                }
                                alt=""
                            />
                        </SocialLinks>
                        <SocialLinks
                            target="_blank"
                            href="https://twitter.com/talropworld/"
                        >
                            <img
                                className="prime"
                                src={
                                    "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/twitter-color.svg"
                                }
                                alt=""
                            />
                            <img
                                className="secondry"
                                src={
                                    "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/twitter.svg"
                                }
                                alt=""
                            />
                        </SocialLinks>
                        <SocialLinks
                            target="_blank"
                            href="https://www.linkedin.com/company/talrop/"
                        >
                            <img
                                className="prime"
                                src={
                                    "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/linkedin-color.svg"
                                }
                                alt=""
                            />
                            <img
                                className="secondry"
                                src={
                                    "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/linkedin.svg"
                                }
                                alt=""
                            />
                        </SocialLinks>
                        <SocialLinks
                            target="_blank"
                            href="https://www.youtube.com/c/talrop/"
                        >
                            <img
                                className="prime"
                                src={
                                    "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/youtube-color.svg"
                                }
                                alt=""
                            />
                            <img
                                className="secondry"
                                src={
                                    "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/youtube.svg"
                                }
                                alt=""
                            />
                        </SocialLinks>
                    </SocialMedia>
                </CopySect>
            </Cover>
        </>
    );
};

export default Footer;
const CopySect = styled.div`
    color: #aaaaaa;
    font-size: 14px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 30px;
    @media (max-width: 1080px) {
        text-align: center;
    }
    @media (max-width: 640px) {
        flex-wrap: wrap-reverse;
        & h4 {
            text-align: center;
            width: 100%;
        }
    }
`;
const Cover = styled.div`
    background-color: #1e1e1e;
    padding: 100px 0 50px;
    @media all and (max-width: 1100px) {
        padding: 80px 0 40px;
    }
    @media all and (max-width: 640px) {
        padding: 60px 0 25px;
    }
    @media all and (max-width: 480px) {
        padding: 30px 0 20px;
    }
`;
const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #333333;
    padding-bottom: 30px;
    @media all and (max-width: 1600px) {
        grid-template-columns: 5fr 3fr;
    }
    @media all and (max-width: 1200px) {
        grid-template-columns: 5fr 4fr;
    }
    @media all and (max-width: 980px) {
        flex-wrap: wrap;
    }
    @media all and (max-width: 640px) {
        grid-gap: 30px;
        padding-bottom: 0px;
    }
`;

const LeftSection = styled.div`
    width: 40%;
    @media all and (max-width: 980px) {
        margin: 0 auto 50px;
    }
    @media all and (max-width: 640px) {
        margin: 0 auto 30px;
    }
`;
const TechRight = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    @media all and (max-width: 768px) {
        flex-wrap: wrap;
    }
`;
const Logo = styled.h1`
    width: 115px;
    transition: all 0.3s ease-in-out;
    &.active {
        width: 50px;
    }
    a {
        display: block;
    }
    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 980px) {
        margin: 0 auto;
        text-align: center;
    }
    @media all and (max-width: 640px) {
        margin: 0 auto;
        width: 70px;
        &.active {
            width: 45px;
        }
    }
    @media all and (max-width: 480px) {
        width: 60px;
        &.active {
            width: 45px;
        }
    }
`;
const Address = styled.p`
    display: block;
    color: #aaaaaa;
    font-size: 15px;
    @media all and (max-width: 1100px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const ProgramSection = styled.div`
    width: 40%;
    @media all and (max-width: 980px) {
        text-align: center;
    }
    @media all and (max-width: 768px) {
        width: 50%;
        margin-bottom: 20px;
    }
    @media all and (max-width: 360px) {
        width: 46%;
    }
    &.third {
        width: 40%;
        @media all and (max-width: 768px) {
            width: 100%;
            margin-bottom: 20px;
        }
    }
    a {
        display: block;
        margin-bottom: 10px;
        color: #aaaaaa;
        font-size: 15px;
        @media all and (max-width: 1100px) {
            font-size: 14px;
        }
        @media all and (max-width: 480px) {
            font-size: 13px;
        }
    }
`;
const FooterTitle = styled.h5`
    font-size: 16px;
    font-family: gordita_medium;
    margin-bottom: 20px;
    color: #fff;
    @media all and (max-width: 1100px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const PhoneLinks = styled.a`
    display: block;
    margin-bottom: 10px;
    color: #aaaaaa;
    font-size: 15px;
    @media all and (max-width: 1100px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
    :last-child {
        margin-bottom: 0px;
    }
`;
const RouterLink = styled(Link)`
    display: block;
    margin-bottom: 10px;
    color: #aaaaaa;
    font-size: 15px;
    @media all and (max-width: 1100px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;

const StartupLink = styled.a`
    display: block;
    margin-bottom: 10px;
    color: #aaaaaa;
    font-size: 15px;
    @media all and (max-width: 1100px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const MailLinks = styled.a`
    display: block;
    margin-bottom: 10px;
    color: #aaaaaa;
    font-size: 15px;
    @media all and (max-width: 1100px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
    :last-child {
        margin-bottom: 0px;
    }
`;
const SocialMedia = styled.span`
    display: flex;
    justify-content: flex-start;

    @media (max-width: 640px) {
        margin: 0 auto 20px;
    }
`;
const SocialLinks = styled.a`
    display: block;
    margin-right: 15px;
    height: 20px;
    .prime {
        display: none;
    }
    &:hover {
        .prime {
            display: block;
        }
        .secondry {
            display: none;
        }
    }

    img {
        display: block;
        height: 100%;
    }
    &:last-child {
        margin-right: 0;
    }
    @media all and (max-width: 1100px) {
        height: 16px;
    }
    @media all and (max-width: 640px) {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
`;
