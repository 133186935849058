import React from "react";
import Lottie from "react-lottie";
import loader from "../../../assets/lottie/buttonloader.json";

export default function ButtonLoader() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loader,
        rendererSettings: {},
    };
    return <Lottie options={defaultOptions} height={45} width={45} />;
}
