import React from "react";
import Header from "../includes/Header";
import ApplyForPrograms from "./ApplyForProgram";
import Footer from "./Footer";
import OurPrograms from "./OurPrograms";
import OurTechies from "./OurTechies";
import SpotLight from "./SpotLight";
import StartUp from "./StartUp";
import StrongEcosystem from "./StrongEcosystem";
import StrongRunningStartup from "./StrongRunningStartup";
import TalropVillage from "./TalropVillage";
import TeamBehind from "./TeamBehind";
import WhyStartups from "./WhyStartups";

import styled from "styled-components";
import Mammootty from "./Mammootty";

const TalropLanding = () => {
	return (
		<div>
			<Container className="App">
				<Header />
				<SpotLight />
				<StartUp />
				<StrongRunningStartup />
				<TeamBehind />
				<Mammootty/>
				<StrongEcosystem />
				<WhyStartups />
				<OurPrograms />
				<OurTechies />
				<TalropVillage />
				<ApplyForPrograms />
				<Footer isHome />
			</Container>
		</div>
	);
};

export default TalropLanding;
const Container = styled.div``;
