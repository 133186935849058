import React from "react";
import styled from "styled-components";

export default function CommunityMissions() {
    const communityMissions = [
        {
            id: 1,
            title: "TAID",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/02-09-2022/TAID.svg",
            description:
                "Talrop's Angel Investors Deck (TAID) is a platform that educates common people about angel investment, its risks & benefits.",
        },
        {
            id: 2,
            title: "Yiaai",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/02-09-2022/Layer+2.svg",
            description:
                "Yiaai is the community OTT platform that provides educational & informational content in various fields like education, technology, entrepreneurship, etc. for free.",
        },
        {
            id: 3,
            title: "eKerala.org",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/mission-three.svg",
            description:
                "eKerala.org is the mission to ensure technology education to each and everyone in Kerala and to thereby promote the growth of education, business and overall development.",
        },
        {
            id: 4,
            title: "TheFemme.org",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/mission-four.svg",
            description:
                "A mission to empower women through education and entrepreneurship. To support their endeavours, paving the path to their financial freedom and emancipation.",
        },
        {
            id: 5,
            title: "Campus Ambassador Program",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/mission-five.svg",
            description:
                "Campus Ambassador Program is a program for college students, where they get the opportunity to be part of Talrop's community and get hands-on exposure in the industry, along with other perks.",
        },
        {
            id: 6,
            title: "Student Ambassador Program",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/mission-six.svg",
            description:
                "The Student Ambassador Program is exclusively for school students who would like to be part of Talrop's community and improve their technological & employability skills.",
        },
        {
            id: 7,
            title: "NRI Community",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/mission-seven.svg",
            description:
                "NRI Community is the community of Indians in different parts of the world. This community plays an important role in taking the products and services from Talrop across the globe.",
        },
        {
            id: 8,
            title: "Professionals Spot",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/mission-eight.svg",
            description:
                "Professionals Spot is a team of experienced professionals from different fields, who provide necessary guidance for startups in their respective fields of expertise.",
        },
    ];
    return (
        <Container>
            <section className="wrapper">
                <Head>
                    <b>Our</b> <span>Missions</span>
                </Head>
                <Description>
                    Innovative missions to build and engage strong community to
                    support startups and its activities.
                </Description>
                <Ul>
                    {communityMissions.map((item) => (
                        <Li key={item.id} data-aos="fade-up">
                            <ProjectImage>
                                <img src={item.image} alt="Project" />
                            </ProjectImage>
                            <Details>
                                <Title>{item.title}</Title>
                                <SubDescription>
                                    {item.description}
                                </SubDescription>
                            </Details>
                        </Li>
                    ))}
                </Ul>
            </section>
        </Container>
    );
}
const Container = styled.section`
    padding: 75px 0;
`;
const Head = styled.h3`
    font-size: 40px;
    font-family: gordita_medium;
    color: #212121;
    text-align: center;
    margin-bottom: 20px;

    & span {
        position: relative;
    }

    & span::before {
        content: "";
        background: url(${"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/underline-green.svg"});
        background-repeat: no-repeat;
        position: absolute;
        background-size: 100%;
        width: 100%;
        left: 0;
        height: 15px;
        bottom: -10px;
    }

    @media all and (max-width: 1400px) {
        font-size: 38px;
    }
    @media all and (max-width: 1280px) {
        font-size: 36px;
    }
    @media all and (max-width: 768px) {
        font-size: 34px;
    }
    @media all and (max-width: 480px) {
        font-size: 28px;
    }
    b {
        color: #0bbe61;
        font-family: gordita_bold;
    }
`;
const Description = styled.p`
    font-size: 18px;
    text-align: center;
    margin-bottom: 60px;
    text-align: center;

    @media all and (max-width: 980px) {
        font-size: 17px;
    }
    @media all and (max-width: 768px) {
        font-size: 16px;
    }
    @media all and (max-width: 640px) {
        & br {
            display: none;
        }
    }
`;
const Ul = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
`;
const Li = styled.div`
    width: 24%;
    margin-bottom: 25px;
    padding: 20px;
    min-height: 450px;
    position: relative;
    background-color: #fff;
    border-radius: 8px;
    transition: all 0.3s ease-in-out 0s;
    border: 1px solid #abd474;
    margin-right: 25px;
    &:hover {
        box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
        border: none;
        border-bottom: 1px solid #abd474;
    }

    &:nth-last-child(-n + 4) {
        margin-bottom: 0;
    }

    :last-child {
        margin-right: 0;
    }

    @media all and (max-width: 1500px) {
        width: 30%;
        min-height: 420px;
        &:nth-last-child(-n + 4) {
            margin-bottom: 30px;
        }
        &:nth-last-child(-n + 2) {
            margin-bottom: 0;
        }
        /* :nth-child(3n) {
            margin-right: 0;
        } */
    }
    @media all and (max-width: 1280px) {
        min-height: 480px;
    }
    @media all and (max-width: 980px) {
        width: 45%;
        min-height: 435px;
        margin-bottom: 18px;
        margin-right: 25px;
        :nth-child(2n) {
            margin-right: 0;
        }
        /* :nth-child(3n) {
            margin-right: 25px;
        } */
        &:nth-last-child(-n + 4) {
            margin-bottom: 20px;
        }
        &:last-child() {
            margin-bottom: 0px;
        }
    }
    @media all and (max-width: 768px) {
        min-height: 475px;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        min-height: 330px;
        margin-right: 0;
        &:nth-last-child(-n + 2) {
            margin-bottom: 20px;
        }
        &:last-child {
            margin-bottom: 0px;
        }
    }
`;
const ProjectImage = styled.div`
    width: 54px;
    height: 106.26px;
    position: relative;
    padding: 15px;
    background-color: #f0f9f6;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    & img {
        width: 200px;
        transform: translateX(15px);
    }

    & p {
        font-family: "gordita_bold";
        font-size: 22px;
        position: absolute;
        bottom: -8px;
        right: -8px;
    }
`;
const Details = styled.div`
    font-size: 23px;
    font-family: "gordita_medium";
`;
const Title = styled.h5`
    color: #545454;
    margin-bottom: 10px;
`;
const SubDescription = styled.p`
    color: #7e7e7e;
    font-size: 17px;

    @media all and (max-width: 980px) {
        font-size: 16px;
    }
    @media all and (max-width: 768px) {
        font-size: 15px;
    }
`;
