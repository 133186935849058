import React from "react";
import styled from "styled-components";
// import { Link } from "react-router-dom";

export default function ApplyForPrograms() {
	const datas = [
		{
			id: 1,
			year: "2 Year",
			icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/upcoming/enter.svg",
			title: "Entrepreneurship School",
			description:
				"This is a two-year program that provides practical entrepreneurship training to mould efficient entrepreneurs.",
			enquiry: [
				{
					icons: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Mail.svg",
					link: "mailto:hello@talrop.com?subject=I have gone through Talrop's website, and read about Entrepreneurship School. I'm interested to know more about it.",
				},
				{
					icons: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Whatsapp.svg",
					link: "https://wa.me/918589999555?text=I have gone through Talrop's website, and read about Entrepreneurship School. I'm interested to know more about it.",
				},
			],
		},
		{
			id: 2,
			year: "2 Year",
			icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/upcoming/tech.svg",
			title: "Technology  School",
			link: "/projects/technology-school/",
			description:
				"This is a 2 year program that provides training on advanced technologies to mould highly skilled engineers.             ",
			enquiry: [
				{
					icons: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Mail.svg",
					link: "mailto:hello@talrop.com?subject=I have gone through Talrop's website, and read about Technology School. I'm interested to know more about it.",
				},
				{
					icons: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Whatsapp.svg",
					link: "https://wa.me/918589999555?text=I have gone through Talrop's website, and read about Technology School. I'm interested to know more about it.",
				},
			],
		},
		{
			id: 3,
			year: "1 Year",
			icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/upcoming/Co-founder.svg",
			title: "Technology Co-founder program (for girls only)",
			description:
				"This is a program to promote women entrepreneurs. Through this program, we mould woman entrepreneurs to become Co-founders of tech startups.",
			enquiry: [
				{
					icons: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Mail.svg",
					link: "mailto:hello@talrop.com?subject=I have gone through Talrop's website, and read about Technology Co-founder program (for girls only). I'm interested to know more about it.",
				},
				{
					icons: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Whatsapp.svg",
					link: "https://wa.me/918589999555?text=I have gone through Talrop's website, and read about Technology Co-founder program (for girls only). I'm interested to know more about it.",
				},
			],
		},
	];

	return (
		<MainContainer className="wrapper">
			<Container id="apply" className="spotlight">
				<Heading>
					Apply for
					<br /> Talrop's Upcoming Programs<span></span>
				</Heading>
				<Description>
					There are many upcoming full-time training programs from
					Talrop, to mould engineers and entrepreneurs. Anyone
					interested can apply.
				</Description>
				<BottomContainer>
					{datas.map((data) => (
						<Card key={data.id} href={data?.link}>
							<Left>
								<IconContainer types={data.title}>
									<Icon src={data.icon} alt="Icon" />
								</IconContainer>
							</Left>
							<Right>
								<Title>{data.title}</Title>
								<Year>{data.year}</Year>
								<Description className="paragraph">
									{data.description}
								</Description>
								<ButtonContainer>
									<Button className="first">
										<p>For Admission </p>
									</Button>
									<Button>
										{data.enquiry.map((item) => (
											<ShareIconContainer
												key={data.id}
												className="watsapp"
												href={item.link}
												target="_blank"
											>
												<ShareIcon
													src={item.icons}
													alt="Icon"
												/>
											</ShareIconContainer>
										))}
									</Button>
								</ButtonContainer>
							</Right>
						</Card>
					))}
				</BottomContainer>
			</Container>
		</MainContainer>
	);
}
const MainContainer = styled.div``;
const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 48px 0px 45px 0px;
	@media all and (max-width: 1280px) {
		padding: 48px 0px 0px 0px;
	}
	@media all and (max-width: 768px) {
		margin-bottom: 0px;
	}
	@media all and (max-width: 440px) {
		padding-top: 50px;
		margin-bottom: 20px;
	}
`;
const Heading = styled.h2`
	font-size: 32px;
	font-family: gordita_medium;
	color: #212121;
	text-align: center;
	margin: 0px auto 50px;
	margin-bottom: 27px;
	letter-spacing: 0.41px;
	text-align: center;
	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: -41px;
		right: 0;
		width: 246px;
		height: 192px;
		background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/backgrounds-color.png")
			no-repeat;
		background-size: contain;
		display: block;
		z-index: -1;
		@media all and (max-width: 768px) {
			top: -69px;
			width: 300px;
			height: 200px;
		}
		@media all and (max-width: 640px) {
			top: -31px;
			left: -42px;
			width: 268px;
			height: 161px;
		}
		@media all and (max-width: 480px) {
			top: -43px;
			left: -42px;
			width: 197px;
			height: 157px;
		}
	}
	span {
		position: absolute;
		width: 158px;
		bottom: -1px;
		height: 7px;
		right: 0;
		background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/line.svg");
		background-repeat: no-repeat;
		background-size: 100% 100%;
		display: block;
		@media all and (max-width: 1200px) {
			width: 132px;
		}
		@media all and (max-width: 480px) {
			width: 104px;
		}
	}
	@media all and (max-width: 1200px) {
		font-size: 27px;
	}
	@media all and (max-width: 980px) {
		text-align: center;
	}
	@media all and (max-width: 640px) {
		margin-bottom: 27px;
	}
	@media all and (max-width: 480px) {
		font-size: 20px;
	}
`;
const BottomContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 54px 0px;
	flex-wrap: wrap;
	@media all and (max-width: 1340px) {
		justify-content: space-around;
	}
	@media all and (max-width: 840px) {
		flex-direction: column;
		padding-bottom: 94px;
	}
	@media all and (max-width: 640px) {
		padding: 37px 0px 66px;
	}
	@media all and (max-width: 480px) {
		padding: 25px 0px 61px;
	}
`;
const Card = styled.a`
	cursor: pointer;
	display: flex;
	background: #ffffff;
	border: 1px solid #d6ecd4;
	border-radius: 14px;
	padding: 26px 20px;
	width: 32%;
	margin-bottom: 40px;
	transition: all 0.3s ease-in-out;
	min-height: 320px;
	position: relative;
	&:last-child {
		@media all and (max-width: 920px) {
			margin-bottom: 0px;
		}
	}
	&:hover {
		box-shadow: 20px 23px 46px #00000017;
		transition: all 0.3s ease-in-out;
		// transform: translateY(-10px);
	}
	@media all and (max-width: 1340px) {
		width: 46%;
		min-height: unset;
	}
	@media all and (max-width: 920px) {
		width: 48%;
	}
	@media all and (max-width: 840px) {
		width: 100%;
	}
	@media all and (max-width: 415px) {
		flex-direction: column;
	}
	@media all and (max-width: 370px) {
		padding: 17px 12px;
	}
`;
const Left = styled.div``;
const Right = styled.div``;
const Year = styled.h2`
	color: #0fa76f;
	font-family: "gordita_medium";
	@media all and (max-width: 480px) {
		font-size: 20px;
	}
`;
const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 53px;
	height: 53px;
	margin-right: 10px;
	@media all and (max-width: 1340px) {
		width: 41px;
		height: 41px;
	}
	@media all and (max-width: 415px) {
		width: 41px;
		height: 41px;
		margin-right: 0px;
		margin-bottom: 15px;
	}
`;
const Icon = styled.img`
	width: 100%;
	display: block;
`;
const Title = styled.h3`
	font-family: "gordita_medium";
	margin-bottom: 5px;
	font-size: 20px;
	@media all and (max-width: 480px) {
		font-size: 16px;
	}
`;
const Description = styled.p`
	letter-spacing: 0px;
	color: #8e8c8c;
	line-height: 1.5;
	margin-top: 10px;
	font-size: 16px;
	width: 70%;
	max-width: 700px;
	text-align: center;
	&.paragraph {
		text-align: left;
		font-size: 14px;
		width: 100%;
	}
	@media all and (max-width: 640px) {
		width: 100%;
	}
`;
const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 23px;
	position: absolute;
	bottom: 30px;
	right: 20px;
	width: calc(100% - 103px);
	@media all and (max-width: 1340px) {
		position: relative;
		bottom: 0;
		right: 0;
		width: 100%;
	}
`;
const Button = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #0fa76f;
	font-size: 16px;
	&.first {
		background: #fff;
		border: 0px solid #fff;
		padding: 7px 0px;
		width: unset;
	}
	p {
		color: #0fa76f;
		font-size: 16px;
		transform: translateY(3px);
		font-family: gordita_medium;
		@media all and (max-width: 768px) {
			font-size: 14px;
			transform: translateY(2px);
		}
	}
`;
const ShareIconContainer = styled.a`
	display: block;
	width: 45px;
	display: flex;
	&.watsapp {
		width: 45px;
		margin-right: 10px;
		&:last-child {
			margin-right: 0px;
		}
	}
`;
const ShareIcon = styled.img`
	width: 100%;
	display: block;
`;
