import React from 'react'
import styled from "styled-components";
import Header from '../new-landing/Header'
import Error from"../../../assets/images/new-landing/error.svg"

function ErrorPage() {
  return (
    <>
    <Header/>
    <Container>
        <ImgContainer>
            <Image src={Error} />
        </ImgContainer>
        <Content>
            <Heading>Page not Found</Heading>
            <Description>You seems to have clicked on a broken link or entered a URL that doesn't exist.</Description>
        </Content>
       
    </Container>
    </>
  )
}

export default ErrorPage

const Container = styled.section`
    padding-top: 290px;
`;
const ImgContainer = styled.div`
    width: 60%;
    margin: 0 auto;
    position: relative;
    @media all and (max-width: 1280px) {
        
    }
    @media all and (max-width: 1080px) {
      
    }
    @media all and (max-width: 980px) {
       
    }
    @media all and (max-width: 768px) {
        
    } 
    @media all and (max-width: 640px) {
    } 
    @media all and (max-width: 480px) {
        
    }
    @media all and (max-width: 360px) {
        
    }
`;
const Image = styled.img`
    display: block;
    width: 100%;
`;
const Content = styled.div`
    text-align: center;
    left: 0;
    right: 0;
    position: absolute;
    top: 500px;
    @media all and (max-width: 1280px) {
        top: 445px;
    }
    @media all and (max-width: 1080px) {
        top: 430px;
    }
    @media all and (max-width: 980px) {
        top: 410px;
    }
    @media all and (max-width: 640px) {
        top: 375px;
    } 
    @media all and (max-width: 480px) {
        top: 340px;
        padding: 20px;
    } 
    @media all and (max-width: 360px) {
        top: 335px;
    }
`;
const Heading = styled.h1`
    font-family: "gordita_medium";
    @media all and (max-width: 1280px) {
        
    }
    @media all and (max-width: 1080px) {
      
    }
    @media all and (max-width: 980px) {
        font-size: 20px;
    }
    @media all and (max-width: 768px) {
        font-size: 18px;
    } 
    @media all and (max-width: 640px) {
    } 
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;
const Description = styled.p`
    width: 32%;
    margin: 0 auto;
    @media all and (max-width: 1280px) {
        width: 40%;
    }
    @media all and (max-width: 1080px) {
        width: 44%;
        font-size: 16px;
    }
    @media all and (max-width: 980px) {
        width: 49%;
        font-size: 14px;
    }
    @media all and (max-width: 768px) {
        width: 59%;
    } 
    @media all and (max-width: 640px) {
        width: 78%;
    } 
    @media all and (max-width: 480px) {
        width: 100%;
        font-size: 12px;
    }
    @media all and (max-width: 360px) {
    }
`;

