import React from "react";
import styled from "styled-components";

export default function AboutSpot() {
    return (
        <Container>
            <section className="wrapper">
                <Left data-aos="fade-right">
                    <Title>
                        Building a Strong{" "}
                        <b>
                            <span>Startup</span>
                            <br /> Ecosystem
                        </b>{" "}
                        in Kerala
                    </Title>
                    <Description>
                        Talrop is building a strong startup ecosystem in Kerala
                        and aims to transform Kerala into a Silicon Valley model
                        by 2030. Talrop has introduced various projects and
                        programs to fulfil this goal.
                    </Description>
                </Left>
                <Right data-aos="fade-left">
                    <img
                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/about-spot.png"
                        alt="Talrop Office"
                    />
                </Right>
            </section>
        </Container>
    );
}

const Container = styled.section`
    padding: 180px 0 0px 0;
    background-image: linear-gradient(#fdfffd, #f0f8ee);
    & .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
`;
const Left = styled.div`
    width: 50%;

    @media all and (max-width: 1080px) {
        width: 100%;
        margin-bottom: 20px;
    }
`;
const Title = styled.h1`
    font-size: 40px;
    font-family: gordita_medium;
    color: #212121;
    text-align: left;
    margin-bottom: 30px;
    line-height: 1.7em;
    & span {
        position: relative;
    }

    & span::before {
        content: "";
        background: url(${"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/UnderLineDiu.png"});
        background-repeat: no-repeat;
        position: absolute;
        background-size: 100%;
        width: 100%;
        height: 15px;
        bottom: -15px;
        left: 0;
    }

    @media all and (max-width: 1400px) {
        font-size: 38px;
    }
    @media all and (max-width: 1280px) {
        font-size: 36px;

        br {
            display: none;
        }
    }
    @media all and (max-width: 1080px) {
        text-align: center;

        br {
            display: block;
        }
    }
    @media all and (max-width: 768px) {
        font-size: 34px;
    }
    @media all and (max-width: 640px) {
        font-size: 32px;
    }
    @media all and (max-width: 480px) {
        font-size: 22px;
    }
    @media all and (max-width: 360px) {
        font-size: 21px;
    }
    b {
        color: #0bbe61;
        font-family: gordita_bold;
    }

    & br {
        @media all and (max-width: 460px) {
            display: none;
        }
    }
`;
const Description = styled.p`
    font-size: 18px;
    text-align: left;
    margin-bottom: 20px;

    @media all and (max-width: 1080px) {
        text-align: center;
    }
    @media all and (max-width: 980px) {
        font-size: 17px;
    }
    @media all and (max-width: 768px) {
        font-size: 16px;
    }
    @media all and (max-width: 480px) {
        font-size: 15px;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
    @media all and (max-width: 1380px) {
        br {
            display: none;
        }
    }
`;
const Right = styled.div`
    width: 50%;

    @media all and (max-width: 1080px) {
        margin: 0 auto;
        width: 75%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
