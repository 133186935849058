import React, { useEffect } from "react";
import AboutSpot from "./new-landing/AboutSpot";
import Footer from "./new-landing/Footer";
import Header from "./new-landing/Header";
import NewTeamBehind from "./new-landing/NewTeamBehind";
import WhyStartups from "./new-landing/WhyStartups";

export default function NewAboutPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/* <Header /> */}
            <AboutSpot />
            <WhyStartups />
            <NewTeamBehind />
            <Footer />
        </>
    );
}
