import React from "react";
import styled from "styled-components";

function StickonSpotlight() {
    return (
        <section className="wrapper">
            <Container>
                <LeftSection data-aos="fade-right">
                    <NumberContainer>
                        <img
                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-09-2022/03_Stikcon.svg"
                            alt="Number01"
                        />
                    </NumberContainer>
                    <Title>
                        <span>Stikcon </span>- An AI based
                        <b> construction company </b> <br />
                    </Title>
                    <Description className="two">
                        Stikcon founded by Top-notch technical and business
                        acumen with a track record in AEC (Architectural
                        Engineering and Construction) Industry.
                    </Description>
                    <Description>
                        Stikcon team includes Architects, Designers, Civil
                        Engineers, MEP Engineers, and Data Scientists who are
                        joining forces to reinvent modern architecture design,
                        construction project management, and increase the
                        efficiency of construction projects through modern, and
                        user-friendly solutions.
                    </Description>
                    <ImageContainer>
                        <img
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/stickonSpotlightImg.png"
                            }
                            alt="Startups"
                        />
                    </ImageContainer>
                    <Button href="https://www.stikcon.com/" target="_blank">
                        Visit Website
                    </Button>
                </LeftSection>
                <RightSection data-aos="fade-left">
                    <img
                        src={
                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/stickonSpotlightImg.png"
                        }
                        alt="Startups"
                    />
                </RightSection>
            </Container>
        </section>
    );
}

export default StickonSpotlight;

const Container = styled.section`
    padding: 100px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 1280px) {
        padding: 80px 0px;
    }
    @media all and (max-width: 980px) {
        flex-direction: column;
    }
`;
const LeftSection = styled.div`
    width: 50%;
    position: relative;
    order: 1;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const NumberContainer = styled.div`
    /* padding: 5px 8px; */
    width: 30%;
    border-radius: 5px;
    @media all and (max-width: 980px) {
        width: 25%;
    }
    @media all and (max-width: 640px) {
        width: 35%;
    }
`;
const Title = styled.h1`
    font-size: 40px;
    margin-top: 30px;
    font-family: gordita_medium;
    color: #212121;
    margin-bottom: 20px;

    & span {
        position: relative;
        font-size: 40px;
        @media all and (max-width: 1280px) {
            font-size: 35px;
        }
        @media all and (max-width: 768px) {
            font-size: 33px;
        }
        @media all and (max-width: 480px) {
            font-size: 26px;
        }
    }

    & span::before {
        content: "";
        background: url(${"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/UnderlineTegain.png"});
        background-repeat: no-repeat;
        position: absolute;
        background-size: 100%;
        width: 100%;
        left: 0;
        height: 15px;
        bottom: -10px;
        z-index: -1;
    }
    @media all and (max-width: 1440px) {
        font-size: 35px;
    }
    @media all and (max-width: 1280px) {
        font-size: 32px;
        margin-top: 20px;
    }
    @media all and (max-width: 980px) {
        font-size: 30px;
        margin-top: 20px;
    }
    @media all and (max-width: 768px) {
        font-size: 26px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
    b {
        color: #06b0cf;
        font-size: 50px;
        @media all and (max-width: 768px) {
            font-size: 36px;
        }
        @media all and (max-width: 1440px) {
            font-size: 40px;
        }
        @media all and (max-width: 980px) {
            font-size: 34px;
            margin-top: 20px;
        }
        @media all and (max-width: 768px) {
            font-size: 26px;
        }
    }
`;
const Description = styled.p`
    font-size: 18px;
    margin-bottom: 30px;

    @media all and (max-width: 980px) {
        font-size: 17px;
    }
    @media all and (max-width: 768px) {
        font-size: 16px;
    }
    &.two {
        margin-bottom: 10px;
    }
`;
const Button = styled.a`
    background: linear-gradient(127.01deg, #05dabf -9.18%, #0798d8 129.96%);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    width: 200px;
    font-size: 18px;
    color: #fff;
    font-family: "gordita_regular";
    @media all and (max-width: 1280px) {
        font-size: 16px;
        width: 180px;
    }
    @media all and (max-width: 768px) {
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        width: 150px;
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        width: 140px;
        font-size: 13px;
    }
`;
const RightSection = styled.div`
    width: 45%;
    order: 2;

    @media all and (max-width: 980px) {
        width: 100%;
        margin-top: 30px;
        display: none;
    }
`;
const ImageContainer = styled.div`
    width: 45%;
    order: 2;
    display: none;
    @media all and (max-width: 980px) {
        width: 100%;
        margin-top: 30px;
        display: block;
        margin-bottom: 30px;
    }
`;
