import "./assets/css/style.css";

import styled from "styled-components";
import SpotLight from "./components/screens/SpotLight";
import Header from "./components/includes/Header";
import StartUp from "./components/screens/StartUp";
import StrongRunningStartup from "./components/screens/StrongRunningStartup";
import TeamBehind from "./components/screens/TeamBehind";
import StrongEcosystem from "./components/screens/StrongEcosystem";
import WhyStartups from "./components/screens/WhyStartups";
import OurPrograms from "./components/screens/OurPrograms";
import OurTechies from "./components/screens/OurTechies";
import TalropVillage from "./components/screens/TalropVillage";
import ApplyForPrograms from "./components/screens/ApplyForProgram";
import Footer from "./components/screens/Footer";
import CacheBuster from "./CacheBuster";
import {BrowserRouter} from 'react-router-dom'
import AppRouter from "./components/routers/routers/AppRouter";
import AOS from 'aos';
import 'aos/dist/aos.css';  

function App() {
    AOS.init();
    return (
        <CacheBuster>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                if (loading) return null;
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload
                    refreshCacheAndReload();
                }

                return (
                    <BrowserRouter>
                   
                    <AppRouter/>
                    </BrowserRouter>
                );
            }}
        </CacheBuster>
    );
}

export default App;

const Container = styled.div``;
