import React from "react";
import styled from "styled-components";

export default function OurPrograms() {
    const datas = [
        {
            id: 1,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/programs/founders.svg",
            title: "Founders Circle ",
            description:
                "Founders Circle is a system introduced by Talrop to find people with innovative ideas and mould new entrepreneurs.",
            enquiry: [
                {
                    icons: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Mail.svg",
                    link: "mailto:hello@talrop.com?subject=I have gone through Talrop's website, and read about Founders Circle. I'm interested to know more about it.",
                },
                {
                    icons: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Whatsapp.svg",
                    link: "https://wa.me/918589999555?text=I have gone through Talrop's website, and read about Founders Circle. I'm interested to know more about it.",
                },
            ],
        },
        {
            id: 2,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/programs/angel.svg",
            title: "Angel Investors Deck",
            description:
                "To successfully build new startups, we need more investors. Angel Investors Deck is a centralised system to find new investors and invest their money in the right startups and offer them high returns.",

            enquiry: [
                {
                    icons: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Mail.svg",
                    link: "mailto:hello@talrop.com?subject=I have gone through Talrop's website, and read about Angel Investors Deck. I'm interested to know more about it.",
                },
                {
                    icons: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Whatsapp.svg",
                    link: "https://wa.me/918589999555?text=I have gone through Talrop's website, and read about Angel Investors Deck. I'm interested to know more about it.",
                },
            ],
        },
        {
            id: 3,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/programs/professional-spot.svg",
            title: "Professionals Spot ",
            description:
                "Professional Spot is a system to find and ensure the services of skilled professionals for Talrop's startups and coordinate them.",
            enquiry: [
                {
                    icons: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Mail.svg",
                    link: "mailto:hello@talrop.com?subject=I have gone through Talrop's website, and read about Professionals Spot. I'm interested to know more about it.",
                },
                {
                    icons: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Whatsapp.svg",
                    link: "https://wa.me/918589999555?text=I have gone through Talrop's website, and read about Professionals Spot. I'm interested to know more about it.",
                },
            ],
        },
        {
            id: 4,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/programs/task-force.svg",
            title: "Task Force",
            description:
                "Task Force is a volunteer team working to support and execute Talrop's mission to build a digital community.",

            enquiry: [
                {
                    icons: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Mail.svg",
                    link: "mailto:hello@talrop.com?subject=I have gone through Talrop's website, and read about Task Force. I'm interested to know more about it.",
                },
                {
                    icons: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Whatsapp.svg",
                    link: "https://wa.me/918589999555?text=I have gone through Talrop's website, and read about Task Force. I'm interested to know more about it.",
                },
            ],
        },
        {
            id: 5,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/programs/startup.svg",
            title: "Startup Community",
            description:
                "Startup Community is a program to raise awareness about startups, through volunteers, and by conducting various events and programs.",

            enquiry: [
                {
                    icons: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Mail.svg",
                    link: "mailto:hello@talrop.com?subject=I have gone through Talrop's website, and read about Startup Community. I'm interested to know more about it.",
                },
                {
                    icons: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/Whatsapp.svg",
                    link: "https://wa.me/918589999555?text=I have gone through Talrop's website, and read about Startup Community. I'm interested to know more about it.",
                },
            ],
        },
    ];

    return (
        <MainContainer id="our-programs">
            <div className="wrapper">
                <Container className="spotlight">
                    <Heading>Talrop's Programs</Heading>
                    <BottomContainer>
                        {datas.map((data) => (
                            <Card key={data.id}>
                                <IconContainer types={data.title}>
                                    <Icon src={data.icon} alt="Icon" />
                                </IconContainer>
                                <Title>{data.title}</Title>
                                <Description>{data.description}</Description>
                                <ButtonContainer>
                                    <Button
                                        className="first"
                                        href="mailto:hello@talrop.com"
                                        target="_blank"
                                    >
                                        <p>For Enquiry </p>
                                    </Button>
                                    <Button>
                                        {data.enquiry.map((item) => (
                                            <ShareIconContainer
                                                key={data.id}
                                                className="whatsapp"
                                                href={item.link}
                                                target="_blank"
                                            >
                                                <ShareIcon
                                                    src={item.icons}
                                                    alt="Icon"
                                                />
                                            </ShareIconContainer>
                                        ))}
                                    </Button>
                                </ButtonContainer>
                            </Card>
                        ))}
                    </BottomContainer>
                </Container>
            </div>
        </MainContainer>
    );
}
const MainContainer = styled.div`
    background: #f9f9f9;
`;
const Container = styled.div`
    padding: 114px 0px 45px 0px;
    @media all and (max-width: 768px) {
        padding-top: 95px;
        margin-bottom: 45px;
    }
    @media all and (max-width: 640px) {
        padding-top: 50px;
        margin-bottom: 25px;
    }
    @media all and (max-width: 420px) {
        padding-bottom: 16px;
        margin-bottom: 0px;
    }
`;
const Heading = styled.h2`
    font-size: 34px;
    font-family: gordita_medium;
    color: #212121;
    text-align: center;
    margin: 0px auto 50px;
    margin-bottom: 70px;
    letter-spacing: 0.41px;
    @media all and (max-width: 1280px) {
        text-align: center;
        margin: 0 auto;
        max-width: 500px;
        margin-bottom: 70px;
    }
    @media all and (max-width: 768px) {
        font-size: 32px;
    }
    @media all and (max-width: 640px) {
        font-size: 30px;
        margin-bottom: 35px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
`;
const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 20px 198px;
    flex-wrap: wrap;
    grid-gap: 30px;
    @media all and (max-width: 1400px) {
        // padding: 20px 145px;
    }
    @media all and (max-width: 1280px) {
        justify-content: center;
        // padding: 20px 50px;
    }
    @media all and (max-width: 980px) {
        grid-gap: 24px;
        justify-content: space-around;
    }
    @media all and (max-width: 768px) {
        justify-content: center;
        grid-gap: 22px;
        padding: 20px 22px;
    }
    @media all and (max-width: 640px) {
        justify-content: center;
        flex-direction: column;
    }
`;
const Card = styled.div`
    position: relative;
    background: #ffffff;
    border: 1px solid #d6ecd4;
    border-radius: 20px;
    padding: 82px 30px 30px;
    width: 31%;
    margin-bottom: 60px;
    transition: all 0.3s ease-in-out;
    min-height: 360px;
    position: relative;
    &:hover {
        // transition: all .3s ease-in-out;
        // transform: translateY(-10px);
        box-shadow: 20px 23px 25px #00000017;
    }
    @media all and (max-width: 1400px) {
        min-height: 360px;
    }
    @media all and (max-width: 1100px) {
        width: 47%;
        margin-bottom: 50px;
        min-height: 330px;
    }
    @media all and (max-width: 980px) {
        padding: 82px 30px 30px;
        min-height: 360px;
    }
    @media all and (max-width: 768px) {
        min-height: unset;
        width: 100%;
        max-width: 500px;
        padding: 63px 25px 25px;
    }
`;
const IconContainer = styled.div`
    position: absolute;
    border-radius: 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    top: -32px;
    left: 24px;
    box-shadow: 8px 14px 16px #0000001a;
    background-color: ${(props) =>
        props.types === "Founders Program"
            ? "#DBEFFF"
            : props.types === "Angel Investors"
            ? "#FFF5E0"
            : props.types === "Professional Spot"
            ? "#FFF4F3"
            : props.types === "Task Force"
            ? "#E9F7F2"
            : "#DBEFFF"};
    @media all and (max-width: 768px) {
        width: 65px;
        height: 65px;
    }
`;
const Icon = styled.img`
    width: 100%;
    display: block;
`;
const Title = styled.h3`
    font-family: "gordita_medium";
    margin-top: 5px;
    font-size: 20px;
    @media all and (max-width: 480px) {
        font-size: 16px;
    }
`;
const Description = styled.p`
    letter-spacing: 0px;
    color: #8e8c8c;
    line-height: 1.5;
    margin-top: 10px;
    font-size: 15px;
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 27px;
    position: absolute;
    width: calc(100% - 60px);
    bottom: 30px;
    left: 30px;
    @media all and (max-width: 768px) {
        width: 100%;
        position: relative;
        bottom: 0;
        left: 0;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        position: relative;
        bottom: 0;
        left: 0;
    }
`;
const Button = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #0fa76f;
    font-size: 16px;

    &.first {
        border: 0px solid #fff;
        padding: 7px 0px;
        width: unset;
    }
    p {
        color: #0fa76f;
        font-size: 16px;
        transform: translateY(3px);
        font-family: gordita_medium;
        @media all and (max-width: 768px) {
            font-size: 14px;
            transform: translateY(2px);
        }
    }
`;
const ShareIconContainer = styled.a`
    display: block;
    width: 45px;
    display: flex;
    &.whatsapp {
        width: 45px;
        margin-right: 10px;
        &:last-child {
            margin-right: 0px;
        }
    }
`;
const ShareIcon = styled.img`
    width: 100%;
    display: block;
`;
