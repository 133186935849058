import React, { useState } from "react";
import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";
import { useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import NewFormModal from "../includes/modal/NewFormModal";
import NewOtpModal from "../includes/modal/NewOtpModal";
import SuccessModal from "../includes/modal/SuccessModal";
import CountrySelector from "../includes/modal/CountrySelector";

const Footer = ({ isHome }) => {
    const [code, setCode] = useState(false);
    const [selected, setSelected] = useState({
        country_code: "IND",
        flag: "https://d38z36hay4oql7.cloudfront.net/media/countries/flags/india.png",
        name: "India",
        phone_code: "+91",
        phone_number_length: 10,
        web_code: "IN",
    });
    const [number, setNumber] = useState("");
    const [name, setName] = useState("");
    const [country, setCountry] = useState("");
    const [isModal, setModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [formModal, setFormModal] = useState(false);
    const [error,setError]=useState(false)
    const programs = [
        { id: 1, name: "Founders Circle", link: "our-programs" },
        { id: 2, name: "Angel Investors Deck", link: "our-programs" },
        { id: 3, name: "Professionals Spot", link: "our-programs" },
        { id: 4, name: "Task Force", link: "our-programs" },
        { id: 5, name: "Startup Community", link: "our-programs" },
    ];
    const company = [
        { id: 1, name: "Techies Park", link: "techies-park" },
        { id: 2, name: "Cyber Squad", link: "cyber-squad" },
        { id: 2, name: "Startup Squad", link: "startup-squad" },
        { id: 2, name: "Commercial Squad", link: "commercial-squad" },
        // { id: 3, name: "Careers", link: "/tos/?s=careers" },
    ];
    const submitNumber = (e) => {
        e.preventDefault();
        if(number.length > 5){
            setFormModal(true);
            setError(false)
        }else{
            setError(true)
        }
    };
    // const projectPrograms = [
    // 	{ id: 1, name: "Founders Circle", link: "/?s=our-programs" },
    // 	{ id: 2, name: "Angel Investors Deck", link: "/?s=our-programs" },
    // 	{ id: 3, name: "Professionals Spot", link: "/?s=our-programs" },
    // 	{ id: 4, name: "Task Force", link: "/?=our-programs" },
    // 	{ id: 5, name: "Startup Community", link: "/?s=our-programs" },
    // ];
    // const projectCompany = [
    // 	{ id: 1, name: "Techies Park", link: "/?s=techies-park" },
    // 	{ id: 2, name: "Cyber Squad", link: "/?s=cyber-squad" },
    // 	{ id: 2, name: "Startup Squad", link: "/?s=startup-squad" },
    // 	{ id: 2, name: "Commercial Squad", link: "/?s=commercial-squad" },
    // 	// { id: 3, name: "Careers", link: "/tos/?s=careers" },
    // ];
    // const [searchParams] = useSearchParams();
    // const nextPath = searchParams.get("next") ? searchParams.get("next") : "/";

    return (
        <>
            <Cover>
                <Container className="wrapper">
                    <LeftSection>
                        <Logo>
                            <a href="/">
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/logo-gray.svg"
                                    alt="Logo"
                                />
                            </a>
                        </Logo>
                        <Address>
                            Talrop Private Limited,&nbsp;
                            <br />
                            3rd floor, Ardra Building,&nbsp;
                            <br />
                            No. 5 Maveli Nagar, Thrikkakkara <br />
                            Pipeline Junction, Kochi, Kerala,
                            <br /> India - 682033
                        </Address>
                    </LeftSection>
                    {isHome ? (
                        <RightSection>
                            <ProgramSection>
                                <FooterTitle>Missions</FooterTitle>
                                <FooterLinkss
                                    target="_blank"
                                    href="https://thefemme.org/"
                                >
                                    TheFemme.org
                                </FooterLinkss>
                                <FooterLinkss
                                    target="_blank"
                                    href="https://ekerala.org/moments/"
                                >
                                    eKerala.org{" "}
                                </FooterLinkss>
                                {/*<FooterLinkss href="">ePanchayat</FooterLinkss>*/}
                            </ProgramSection>
                            <ProgramSection>
                                <FooterTitle>Contact</FooterTitle>
                                <PhoneLinks href="tel:+91 858 9999 552">
                                    +91 858 9999 555
                                </PhoneLinks>
                                <MailLinks href="mailto:hello@talrop.com">
                                    hello@talrop.com
                                </MailLinks>
                                <SocialMedia>
                                    <SocialLinks
                                        target="_blank"
                                        href="https://www.linkedin.com/company/talrop/"
                                    >
                                        <img
                                            className="prime"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/linkedin-color.svg"
                                            }
                                            alt=""
                                        />
                                        <img
                                            className="secondry"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/linkedin.svg"
                                            }
                                            alt=""
                                        />
                                    </SocialLinks>
                                    <SocialLinks
                                        target="_blank"
                                        href="https://www.youtube.com/c/talrop/"
                                    >
                                        <img
                                            className="prime"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/youtube-color.svg"
                                            }
                                            alt=""
                                        />
                                        <img
                                            className="secondry"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/youtube.svg"
                                            }
                                            alt=""
                                        />
                                    </SocialLinks>
                                    <SocialLinks
                                        target="_blank"
                                        href="https://www.instagram.com/talropworld/"
                                    >
                                        <img
                                            className="prime
                                "
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/instagram-color.svg"
                                            }
                                            alt=""
                                        />
                                        <img
                                            className="secondry"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/instagram.svg"
                                            }
                                            alt=""
                                        />
                                    </SocialLinks>
                                    <SocialLinks
                                        target="_blank"
                                        href="https://www.facebook.com/talropworld/"
                                    >
                                        <img
                                            className="prime"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/facebook-color.svg"
                                            }
                                            alt=""
                                        />
                                        <img
                                            className="secondry"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/facebook.svg"
                                            }
                                            alt=""
                                        />
                                    </SocialLinks>
                                    <SocialLinks
                                        target="_blank"
                                        href="https://twitter.com/talroppworld/"
                                    >
                                        <img
                                            className="prime"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/twitter-color.svg"
                                            }
                                            alt=""
                                        />
                                        <img
                                            className="secondry"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/twitter.svg"
                                            }
                                            alt=""
                                        />
                                    </SocialLinks>
                                </SocialMedia>
                            </ProgramSection>

                            <ProgramSection>
                                <FooterTitle>Projects</FooterTitle>
                                {company.map((data) => (
                                    <FooterLinks
                                        key={data.id}
                                        activeClass="active"
                                        to={data.link}
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={300}
                                    >
                                        {data.name}
                                    </FooterLinks>
                                ))}
                            </ProgramSection>
                            <ProgramSection>
                                <FooterTitle>Programs</FooterTitle>
                                {programs.map((data) => (
                                    <FooterLinks
                                        key={data.id}
                                        activeClass="active"
                                        to={data.link}
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={300}
                                    >
                                        {data.name}
                                    </FooterLinks>
                                ))}
                            </ProgramSection>
                        </RightSection>
                    ) : (
                        <TechRight>
                            
                            <ProgramSection className="contact">
                                <FooterTitle>Contact</FooterTitle>
                                <PhoneLinks href="tel:+91 858 999 8016">
                                +91 858 999 8016
                                </PhoneLinks>
                                <PhoneLinks href="tel:+91 730 602 3057">
                                +91 730 602 3057
                                </PhoneLinks>
                                <MailLinks href="mailto:hello@talrop.com">
                                    hello@talrop.com
                                </MailLinks>
                                <SocialMedia>
                                    <SocialLinks
                                        target="_blank"
                                        href="https://www.linkedin.com/company/talrop/"
                                    >
                                        <img
                                            className="prime"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/linkedin-color.svg"
                                            }
                                            alt=""
                                        />
                                        <img
                                            className="secondry"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/linkedin.svg"
                                            }
                                            alt=""
                                        />
                                    </SocialLinks>
                                    <SocialLinks
                                        target="_blank"
                                        href="https://www.youtube.com/c/talrop/"
                                    >
                                        <img
                                            className="prime"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/youtube-color.svg"
                                            }
                                            alt=""
                                        />
                                        <img
                                            className="secondry"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/youtube.svg"
                                            }
                                            alt=""
                                        />
                                    </SocialLinks>
                                    <SocialLinks
                                        target="_blank"
                                        href="https://www.instagram.com/talropworld/"
                                    >
                                        <img
                                            className="prime
                                "
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/instagram-color.svg"
                                            }
                                            alt=""
                                        />
                                        <img
                                            className="secondry"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/instagram.svg"
                                            }
                                            alt=""
                                        />
                                    </SocialLinks>
                                    <SocialLinks
                                        target="_blank"
                                        href="https://www.facebook.com/talropworld/"
                                    >
                                        <img
                                            className="prime"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/facebook-color.svg"
                                            }
                                            alt=""
                                        />
                                        <img
                                            className="secondry"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/facebook.svg"
                                            }
                                            alt=""
                                        />
                                    </SocialLinks>
                                    <SocialLinks
                                        target="_blank"
                                        href="https://twitter.com/talroppworld/"
                                    >
                                        <img
                                            className="prime"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/twitter-color.svg"
                                            }
                                            alt=""
                                        />
                                        <img
                                            className="secondry"
                                            src={
                                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-09-2021/twitter.svg"
                                            }
                                            alt=""
                                        />
                                    </SocialLinks>
                                </SocialMedia>
                            </ProgramSection>
                            <ProgramSection className="quote">
                                {/* <FooterTitle>Get a Quote</FooterTitle> */}
                                <FooterLinkss target="_blank" href="#">
                                    We will reach you as soon as possible,
                                </FooterLinkss>
                                <FormSection>
                                    <FormCover>
                                        <LeftFlag
                                            onClick={() => setCode(!code)}
                                        >
                                            <ImageCont>
                                                <img
                                                    src={selected.flag}
                                                    alt="flag"
                                                />
                                            </ImageCont>
                                            <Code>{selected.phone_code}</Code>
                                        </LeftFlag>
                                        <input
                                            type="number"
                                            placeholder="Enter phone number"
                                            onChange={(e) =>
                                                setNumber(e.target.value)
                                            }
                                            value={number}
                                        />
                                        <input
                                            className="button"
                                            type="submit"
                                            value="Apply Now"
                                            onClick={(e) => submitNumber(e)}
                                        />
                                    </FormCover>
                                    {error && <Error>Enter valid number</Error>}
                                </FormSection>
                            </ProgramSection>
                        </TechRight>
                    )}
                </Container>
                {/* <BottomSection className="wrappertg">
                    <CopyRight>©2022, Talrop Private Limited</CopyRight> */}
                    {/*<Privacy to="/">Terms of Service</Privacy>
                                        <Privacy to="/">Privacy Policy</Privacy>*/}
                    {/* <RightSide>
                    <FooterLinks className="lasts" href="/terms-of-service/">
                        Terms of Service
                    </FooterLinks>
                    <FooterLinks
                        className="lasts"
                        style={{ marginLeft: 20 }}
                        href="/privacy-policy/"
                    >
                        Privacy Policy
                    </FooterLinks>
                </RightSide> */}
                {/* </BottomSection> */}
                <CopySect className="wrappertg">
                    <h4>©2022, Talrop Private Limited</h4>
                </CopySect>
            </Cover>
            {isModal && (
                <NewOtpModal
                    isModal={isModal}
                    setModal={setModal}
                    number={number}
                    selected={selected}
                    setName={setName}
                    setNumber={setNumber}
                    setFormModal={setFormModal}
                    setSuccess={setSuccess}
                />
            )}
            {<SuccessModal success={success} setSuccess={setSuccess} />}
            {formModal && (
                <NewFormModal
                    formModal={formModal}
                    setFormModal={setFormModal}
                    number={number}
                    selected={selected}
                    setSelected={setSelected}
                    setModal={setModal}
                    setNumber={setNumber}
                    isModal={isModal}
                    name={name}
                    setName={setName}
                    setCountry={setCountry}
                />
            )}
            <CountrySelector
                selected={selected}
                setSelected={setSelected}
                code={code}
                setCode={setCode}
                setCountry={setCountry}
            />
        </>
    );
};

export default Footer;
const CopySect = styled.div`
    // text-align:center;
    color:#aaaaaa;
    font-size:14px;
    margin-top:20px;
    padding-left:35px;
    
    @media(max-width:640px){
        margin-top:20px;
    }
    @media(max-width:1080px){
        text-align:center;
    }
`;
const Cover = styled.div`
    background-color: #1e1e1e;
    padding: 100px 0 50px;
    @media all and (max-width: 1100px) {
        padding: 80px 0 40px;
    }
    @media all and (max-width: 640px) {
        padding: 60px 0 25px;
    }
    @media all and (max-width: 480px) {
        padding: 60px 0 20px;
    }
`;
const Container = styled.div`
    display: grid;
    grid-template-columns: 5fr 5fr;
    grid-gap: 50px;
    @media all and (max-width: 1150px) {
        grid-template-columns: 1fr;
    }
    @media all and (max-width: 640px) {
        grid-gap: 30px;
    }
`;

const LeftSection = styled.div`
    @media all and (max-width: 1150px) {
        max-width: 480px;
        margin: 0 auto;
        text-align: center;
    }
`;
const RightSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media all and (max-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media all and (max-width: 500px) {
        grid-template-columns: 1fr 1fr;
    }
`;
const Error = styled.h4`
    font-size:13px;
    color:red;

`;
const TechRight = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width:100%;
    @media all and (max-width: 640px) {
        flex-direction: column-reverse;
    }
`;
const Logo = styled.h1`
    width: 70px;
    transition: all 0.3s ease-in-out;
    &.active {
        width: 50px;
    }
    a {
        display: block;
    }
    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 1150px) {
        margin: 0 auto;
    }
    @media all and (max-width: 640px) {
        width: 70px;
        &.active {
            width: 45px;
        }
    }
    @media all and (max-width: 480px) {
        width: 60px;
        &.active {
            width: 45px;
        }
    }
`;
const Address = styled.p`
    font-size: 16px;
    margin-top: 30px;
    color: #aaaaaa;
    line-height: 28px;
    @media all and (max-width: 1100px) {
        font-size: 14px;
        margin-top: 20px;
    }
    @media all and (max-width: 1150px) {
        br {
            display: none;
        }
    }
`;
const CopyRight = styled.p`
    font-size: 16px;
    color: #aaaaaa;
    // float:left;
    // text-align:center;
    @media all and (max-width: 1100px) {
        font-size: 12px;
    }
    @media all and (max-width: 640px) {
        text-align: center;
    }
    @media all and (max-width: 480px) {
        text-align: center;
        width: 100%;
		margin-bottom: 10px;
    }
`;
const ProgramSection = styled.div`
    @media all and (max-width: 768px) {
        margin-bottom: 20px;
    }
    
    &.mission {
        width: 20%;
        @media all and (max-width: 768px) {
            width: 47%;
            text-align: center;
        }
    }
    &.contact {
        width: 30%;
        @media all and (max-width: 768px) {
            width: 25%;
            text-align: center;
            & span {
                justify-content: center;
            }
        }
        @media all and (max-width: 640px) {
            width: 50%;
            margin:0 auto;
            text-align: center;
            & span {
                justify-content: center;
            }
        }
        // @media 
    }
    &.quote {
        width: 60%;
        @media(max-width:1080px){
            width:40%;
        }
        @media(max-width:980px){
            width:50%;
        }
        @media(max-width:768px){
            width:58%;
        }
        // @media all and (max-width: 768px) {
        //     width: 75%;
        //     margin: 0 auto;
        //     text-align: center;
        // }
        @media all and (max-width: 640px) {
            text-align:center;
            margin:0 auto;
            margin-bottom:20px;
            width:80%;
        }
        @media all and (max-width: 480px) {
            width: 90%;
        }
    }
    @media all and (max-width: 680px) {
        margin-bottom: 30px;
    }
`;
const FooterTitle = styled.h5`
    font-size: 16px;
    font-family: gordita_medium;
    margin-bottom: 20px;
    color: #fff;
    @media all and (max-width: 1100px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const FooterLinks = styled(ScrollLink)`
    cursor: pointer;
    display: block;
    margin-bottom: 10px;
    color: #aaaaaa;
    font-size: 15px;
    &.lasts {
        @media all and (max-width: 450px) {
            margin-bottom: 0px;
        }
    }
    @media all and (max-width: 1100px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const ProjectLinks = styled(Link)`
    cursor: pointer;
    display: block;
    margin-bottom: 10px;
    color: #aaaaaa;
    font-size: 15px;
    &.lasts {
        @media all and (max-width: 450px) {
            margin-bottom: 0px;
        }
    }
    @media all and (max-width: 1100px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const FooterLinkss = styled.a`
    cursor: pointer;
    display: block;
    margin-bottom: 10px;
    color: #aaaaaa;
    font-size: 15px;
    &.lasts {
        @media all and (max-width: 450px) {
            margin-bottom: 0px;
        }
    }
    @media all and (max-width: 1100px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const PhoneLinks = styled.a`
    display: block;
    margin-bottom: 10px;
    color: #aaaaaa;
    font-size: 15px;
    @media all and (max-width: 1100px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const MailLinks = styled.a`
    display: block;
    margin-bottom: 10px;
    color: #aaaaaa;
    font-size: 15px;
    @media all and (max-width: 1100px) {
        font-size: 14px;
    }
    @media all and (max-width: 480px) {
        font-size: 13px;
    }
`;
const SocialMedia = styled.span`
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
    @media all and (max-width: 1100px) {
        margin-top: 20px;
    }
`;
const SocialLinks = styled.a`
    display: block;
    margin-right: 15px;
    height: 20px;
    .prime {
        display: none;
    }
    &:hover {
        .prime {
            display: block;
        }
        .secondry {
            display: none;
        }
    }

    img {
        display: block;
        height: 100%;
    }
    &:last-child {
        margin-right: 0;
    }
    @media all and (max-width: 1100px) {
        height: 16px;
    }
    @media all and (max-width: 640px) {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
`;

const BottomSection = styled.div`
    margin-top: 50px !important;
    display: flex;
    flex-wrap: wrap;
    width:90%;
    margin:0 auto;
    margin-left:100px;
    /* display: flex;
	justify-content: center; */
    /* @media all and (max-width: 640px) {
        margin-top: 30px !important;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    } */
`;

const RightSide = styled.div`
    display: flex;
    justify-content: flex-end;
    @media all and (max-width: 640px) {
        justify-content: space-between;
    }
`;
const FormSection = styled.form`
    // width:80%;
`;
const FormCover = styled.div`
    width: 100%;
    padding: 5px 5px 5px 10px;
    background: #303030;
    border: 0.838519px solid #373737;
    border-radius: 5.03112px;
    display: flex;
    margin-top: 30px;
    position: relative;
    input {
        width: 55%;
        // background:red;
        margin-left: 5px;
        font-size: 14px;
        color: #fff;
        &::placeholder {
            @media(max-width:420px){
                font-size:12px;
            }
        }
        &.button {
            background: #0fa674;
            font-family: "gordita_medium";
            width: 30%;
            border-radius: 2.51556px;
            height: 40px;
            color: #fff;
            cursor: pointer;
            font-size: 14px;
            @media (max-width: 640px) {
                font-size: 12px;
                height: 35px;
            }
            @media (max-width: 360px) {
                font-size: 11px;
            }
        }
    }
`;
const LeftFlag = styled.div`
    display: flex;
    // width: 15%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    @media (max-width: 980px) {
        width: 20%;
    }
    @media (max-width: 820px) {
        width: 22%;
    }
`;
const ImageCont = styled.div`
    width: 30px;
    height: 30px;
    // background: green;
    overflow: hidden;
    border-radius: 50%;
    margin-right:8px;
    @media (max-width: 640px) {
        width: 20px;
        height: 20px;
    }
    img {
        width: 150%;
        display: block;
        object-fit: contain;
        transform: translateX(-8px);
        @media (max-width: 640px) {
            transform: translateX(-5px);
        }
    }
`;
const Code = styled.div`
    font-size: 14px;
    color: #fff;
    @media (max-width: 640px) {
        font-size: 13px;
    }
`;
const Privacy = styled(Link)`
    font-size: 16px;
    color: #aaaaaa;
    &:nth-child(2) {
        margin: 0 10px 0 auto;
        @media all and (max-width: 480px) {
            margin-right: 0px;
        }
    }
    @media all and (max-width: 1100px) {
        font-size: 12px;
    }
    @media all and (max-width: 640px) {
        text-align: center;
    }
    @media all and (max-width: 480px) {
        text-align: center;
        width: 50%;
    }
`;
