import React from "react";
import styled from "styled-components";

function StrongRunningStartup() {
    const startUps = [
        {
            id: 1,
            name: "Steyp",
            logo: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/company-logo/steyp.png",
            website_link: "https://www.steyp.com",
            website_name: "www.steyp.com",
            description: `Steyp is an edtech company, the beginning of Digital AI University, which prepares school-college 
            students and graduates to build a career in the technology field and to become tech entrepreneurs.`,
        },
        {
            id: 2,
            name: "Tegain",
            logo: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/company-logo/tegain.png",
            website_link: "https://www.tegain.com",
            website_name: "www.tegain.com",
            description: `Tegain is a virtual IT firm with an organised network of professionals working remotely and executing projects worldwide.`,
        },
        {
            id: 3,
            name: "Dett",
            logo: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/company-logo/dett.png",
            website_link: "https://www.dett.app/",
            website_name: "www.dett.app",
            description: `Dett is a virtual gifting platform, an ultimate gifting solution to make all the special occasions memorable.`,
        },
        {
            id: 4,
            name: "Daistot",
            logo: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/company-logo/daistot.png",
            website_link: "https://www.daistot.com",
            website_name: "www.daistot.com",
            description: `Daistot is an edu-entertainment social media platform for school students to promote their extracurricular activities.`,
        },
        {
            id: 5,
            name: "Kinfig",
            logo: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/company-logo/kinfig.png",
            website_link: "https://www.kinfig.com",
            website_name: "www.kinfig.com",
            description: `Kinfig is a social media platform for families to bring them together, share emotions and cherish moments.`,
        },

        {
            id: 6,
            name: "Grolius",
            logo: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/grolius-92.png",
            website_link: "https://www.grolius.com",
            website_name: "www.grolius.com",
            description: `Grolius is a dedicated social media platform for alumni of schools, colleges or any organisation.
            `,
        },
        {
            id: 7,
            name: "Wise Talkies",
            logo: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/company-logo/wise-talk.png",
            website_link: "http://wisetalkies.com/",
            website_name: "www.wisetalkies.com",
            description: `Wise Talkies is a learning platform for media and film studies which deal with all the aspects of the art and entertainment business.`,
        },
        {
            id: 8,
            name: "Stikcon",
            logo: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/stikon.png",
            website_link: "https://stikcon.com/",
            website_name: "www.stikcon.com",
            description: `Stikcon is a centralised construction management platform that handles everything from consultation to services.`,
        },
        {
            id: 9,
            name: "Diuu",
            logo: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-01-2022/diuu-min.png",
            website_link: "https://diuu.ai/",
            website_name: "www.diuu.ai",
            description: `A Marketplace for Digital Universe.`,
        },
        {
            id: 10,
            name: "Ykipp",
            logo: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-01-2022/ykipp-min.png",
            website_link: "https://ykipp.com/",
            website_name: "www.ykipp.com",
            description: `Ykipp is transforming schools to a next level education experience by automating administration and academics, implementing innovative ideas and programs.`,
        },
    ];
    return (
        <Container id="strong-running-startup">
            <div className="wrapper">
                <Title>Startups from Talrop</Title>
                <Description>
                    Currently, we have launched 10 startups out of 140 we are
                    planning to launch. The services of the following 10
                    startups are now available.
                </Description>
                <CardSection>
                    {startUps.map((data) => (
                        <Cards key={data.id}>
                            <LogoOuter>
                                <Logo>
                                    <img src={data.logo} alt="" />
                                </Logo>
                            </LogoOuter>
                            <CardTitle>{data.name}</CardTitle>
                            <CardDescription>
                                {data.description}
                            </CardDescription>
                            <Button href={data.website_link} target="_blank">
                                Visit : {data.website_name}
                            </Button>
                        </Cards>
                    ))}
                </CardSection>
            </div>
        </Container>
    );
}

export default StrongRunningStartup;

const Container = styled.div`
    background-color: #f9f9f9;
    padding: 120px 0;
    @media all and (max-width: 768px) {
        padding: 100px 0;
    }
    @media all and (max-width: 640px) {
        padding: 80px 0;
    }
    @media all and (max-width: 360px) {
        padding: 70px 0;
    }
`;
const Title = styled.h2`
    font-size: 34px;
    font-family: gordita_medium;
    text-align: center;
    max-width: 500px;
    position: relative;
    margin: 0 auto;
    z-index: 9;

    &::before {
        content: "";
        position: absolute;
        top: -30px;
        left: 0px;
        width: 100%;
        height: 200px;
        background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/background-color-gray.png")
            no-repeat;
        background-size: contain;
        display: block;
        z-index: -1;
        opacity: 0.7;
    }
    @media all and (max-width: 1280px) {
        font-size: 32px;
    }
    @media all and (max-width: 768px) {
        font-size: 30px;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
    @media all and (max-width: 360px) {
        font-size: 23px;
    }
`;
const Description = styled.p`
    text-align: center;
    font-size: 16px;
    max-width: 600px;
    position: relative;
    margin: 0 auto;
    z-index: 9;
    margin-top: 30px;
    @media all and (max-width: 1280px) {
        margin-top: 25px;
    }
    @media all and (max-width: 640px) {
        font-size: 15px;
    }
    @media all and (max-width: 360px) {
        font-size: 14px;
    }
`;

const CardSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -10px;
    margin-top: 30px;
    @media all and (max-width: 980px) {
        margin: 0 -20px;
        margin-top: 30px;
    }
    @media all and (max-width: 768px) {
        margin-top: 20px;
        margin: 0 -15px;
    }
    @media all and (max-width: 640px) {
        margin: 0;
    }
`;
const Cards = styled.div`
    width: calc(25% - 20px);
    margin: 0 10px;
    padding: 20px;
    border: 1px solid #d6ecd4;
    border-radius: 15px;
    padding-bottom: 30px;
    transition: all 0.3s ease-in-out;
    margin-top: 100px;
    position: relative;
    background-color: #fff;
    padding-top: 50px;
    min-height: 350px;
    &:hover {
        box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    }
    @media all and (max-width: 1280px) {
        width: calc(33% - 20px);
    }
    @media all and (max-width: 980px) {
        width: calc(50% - 40px);
        margin: 0 20px;
        margin-top: 100px;
    }
    @media all and (max-width: 768px) {
        width: calc(50% - 30px);
        margin: 0 15px;
        margin-top: 80px;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        margin: 0;
        margin-top: 80px;
        min-height: unset;
    }
    @media all and (max-width: 360px) {
        padding: 15px;
        padding-top: 40px;
    }
`;

const LogoOuter = styled.div`
    width: 110px;
    padding: 7px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #0bbe61;
    position: absolute;
    top: -55px;
    @media all and (max-width: 1100px) {
        width: 100px;
        top: -50px;
    }
`;

const Logo = styled.span`
    display: block;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    border-radius: 50%;
    overflow: hidden;
    img {
        display: block;
        width: 100%;
        border-radius: 50%;
        overflow: hidden;
    }
`;
const CardTitle = styled.h4`
    font-size: 18px;
    font-family: gordita_medium;
    color: #545454;
    margin-top: 20px;
`;
const CardDescription = styled.p`
    margin-top: 10px;
    font-size: 14px;
    color: #7e7e7e;
`;

const Button = styled.a`
    /* width: 100%; */
    width: calc(100% - 40px);
    height: 50px;
    display: block;
    background-color: #d5f0e6;
    /* border-radius: 5px; */
    font-size: 14px;
    font-family: gordita_medium;
    color: #0fa76f;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    transition: all 0.3s ease-in-out;
    position: absolute;
    left: 20px;
    bottom: 20px;
    &:hover {
        opacity: 0.8;
    }
    @media all and (max-width: 640px) {
        position: relative;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    @media all and (max-width: 360px) {
    }
`;
