import React, { useState } from "react";
import styled from "styled-components";

const Header = () => {
    return (
        <Container>
            <div className="wrapper">
                <Cover>
                    <LeftSection>
                        <Logo>
                            <a href="/">
                                <img
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/talrop-logo.png"
                                    alt="Logo"
                                />
                            </a>
                        </Logo>
                    </LeftSection>
                    <RightSection></RightSection>
                </Cover>
            </div>
        </Container>
    );
};

export default Header;

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 50px;
    z-index: 99999;
    transition: all 0.3s ease-in-out;
    &.active {
        background-color: #fff;
        padding: 20px 0;
        box-shadow: 0 16px 24px rgb(0 0 0 / 3%);
    }
    @media all and (max-width: 640px) {
        padding-top: 30px;
        &.active {
            padding: 10px 0;
        }
    }
`;
const Cover = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &.active {
        align-items: center !important;
    }
`;

const LeftSection = styled.div``;
const RightSection = styled.div``;

const Logo = styled.h1`
    width: 80px;
    transition: all 0.3s ease-in-out;
    &.active {
        width: 50px;
    }
    a {
        display: block;
    }
    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 640px) {
        width: 70px;
        &.active {
            width: 45px;
        }
    }
    @media all and (max-width: 480px) {
        width: 50px;
        // &.active {
        //     width: 45px;
        // }
    }
`;

const HandBurger = styled.span`
    cursor: pointer;
    width: 60px;
    display: block;
    img {
        display: block;
        width: 100%;
    }
    &.active {
        width: 50px;
    }
    @media all and (max-width: 640px) {
        width: 50px;
        &.active {
            width: 40px;
        }
    }
    @media all and (max-width: 640px) {
        width: 45px;
        &.active {
            width: 45px;
        }
    }
`;
