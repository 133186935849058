import React from "react";
import styled from "styled-components";

function StrongStartup() {
    return (
        <Container>
            <LeftSection>
                <img
                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/strong-startup.png"
                    alt=""
                />
            </LeftSection>
            <RightSection>
                <Title>Why Kerala needs a strong Startup Ecosystem?</Title>
                <Description>
                    The backbone of Kerala's economy is the incoming foreign
                    funds. To accelerate its influx, our products and services
                    need to reach the international market. This is possible
                    when there are more innovative entrepreneurs and new
                    startups.
                    <br />
                    <br />
                    The current ecosystem in Kerala is not yet fully adequate to
                    promote entrepreneurs and startups. The different aspects of
                    starting a venture, like its idea validation, team
                    development, funding, etc are not hassle-free as they should
                    be. This is why Kerala needs a strong startup ecosystem to
                    build more startups and promote our economic growth.
                </Description>
            </RightSection>
        </Container>
    );
}

export default StrongStartup;

const Container = styled.div`
    padding-top: 130px;
    display: grid;
    grid-template-columns: 2fr 4fr;
    grid-gap: 70px;
    width: 80%;
    margin: 0 auto;
    @media all and (max-width: 1280px) {
        width: 90%;
        grid-gap: 50px;
    }
    @media all and (max-width: 1100px) {
        width: 95%;
    }
    @media all and (max-width: 768px) {
        width: 100%;
        padding-top: 110px;
        grid-template-columns: 1fr;
    }
    @media all and (max-width: 640px) {
        padding-top: 100px;
    }
    @media all and (max-width: 480px) {
        padding-top: 80px;
    }
    @media all and (max-width: 70px) {
        padding-top: 70px;
    }
`;
const LeftSection = styled.div`
    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 768px) {
        max-width: 300px;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Title = styled.h3`
    font-size: 34px;
    font-family: gordita_medium;
    color: #000;
    max-width: 500px;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: -50px;
        left: 0px;
        width: 100%;
        height: 200px;
        background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/backgrounds-color.png")
            no-repeat;
        background-size: contain;
        display: block;
        z-index: -1;
        opacity: 0.7;
    }
    @media all and (max-width: 1280px) {
        font-size: 32px;
    }
    @media all and (max-width: 768px) {
        font-size: 30px;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
`;
const Description = styled.p`
    margin-top: 30px;
    @media all and (max-width: 1280px) {
        margin-top: 25px;
    }
    @media all and (max-width: 768px) {
        width: 90%;
    }
    @media all and (max-width: 640px) {
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        width: 100%;
        font-size: 14px;
    }
`;
