import React from "react";
import styled from "styled-components";

function StartupsSpotlight() {
    return (
        <Container>
            <BgContainer>
                <section className="wrapper">
                    <LeftSection data-aos="fade-right">
                        <Title>
                            <b>Startups</b> from <span>Talrop</span>
                        </Title>
                        <Description>
                            We have launched 10 startups and ought to build a
                            total of 140 startups from Kerala. The services of
                            currently launched startups are now available.
                        </Description>
                    </LeftSection>
                    <RightSection data-aos="fade-left">
                        <img
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/StartupspotlightImg.png"
                            }
                            alt="Startups"
                        />
                    </RightSection>
                </section>
            </BgContainer>
        </Container>
    );
}

export default StartupsSpotlight;
const Container = styled.section`
    padding: 140px 0 0px 0;
    background: #f1f9ef;
    @media all and (max-width: 1280px) {
        padding: 140px 0 0px 0;
    }

    @media all and (max-width: 768px) {
        padding: 86px 0 0 0;
    }
    @media all and (max-width: 768px) {
        padding: 100px 0 0 0;
    }
    @media all and (max-width: 640px) {
        display: flex;
        flex-direction: column;
    }
`;
const BgContainer = styled.div`
    padding: 62px 0 75px 0;
    background-image: url(${"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/StartupspotlightImg.png"});
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 50%;

    @media all and (max-width: 1280px) {
        padding: 50px 0 20px 0;
    }
    @media all and (max-width: 1080px) {
        /* background-size: 52%; */
        padding: 50px 0 0px 0;
    }
    @media all and (max-width: 980px) {
        background-image: none;
    }
`;
const LeftSection = styled.div`
    width: 50%;
    position: relative;
    padding-top: 60px;
    @media all and (max-width: 1280px) {
        padding-top: 30px;
    }
    @media all and (max-width: 980px) {
        width: 100%;
        margin-bottom: 20px;
    }
    @media all and (max-width: 640px) {
        width: 100%;
    }
`;
const Title = styled.h1`
    font-size: 40px;
    font-family: gordita_medium;
    color: #212121;
    margin-bottom: 20px;

    & span {
        position: relative;
    }

    & span::before {
        content: "";
        background: url(${"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/underline-green.svg"});
        background-repeat: no-repeat;
        position: absolute;
        background-size: 100%;
        width: 100%;
        left: 0;
        height: 15px;
        bottom: -10px;
    }

    @media all and (max-width: 1400px) {
        font-size: 38px;
    }
    @media all and (max-width: 1280px) {
        font-size: 36px;
    }
    @media all and (max-width: 980px) {
        font-size: 30px;
        text-align: center;
    }
    @media all and (max-width: 768px) {
        font-size: 26px;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
    }
    b {
        color: #62af84;
    }
`;
const Description = styled.p`
    font-size: 18px;
    margin-bottom: 30px;

    @media all and (max-width: 980px) {
        font-size: 16px;
        text-align: center;
    }
    @media all and (max-width: 768px) {
        font-size: 14px;
    }
    @media all and (max-width: 640px) {
        font-size: 16px;
    }
`;
const RightSection = styled.div`
    width: 45%;
    display: none;
    @media all and (max-width: 980px) {
        display: block;
        margin: 0 auto;
        width: 75%;
    }
    @media all and (max-width: 480px) {
        width: 100%;
    }
`;
