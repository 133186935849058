import React from "react";
import styled from "styled-components";

function FundamentBulding() {
    const buldingBlocks = [
        {
            id: 1,
            link: "techies-park",
            name: "Techies Park",
            thumnail:
                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/techies-park.png",
            description: `Techies Park is an infrastructure project that Talrop builds by spending 
            15 lakh to 1 crore each in selected schools and colleges of 140 assembly constituencies of Kerala.
            `,
        },
        {
            id: 2,
            link: "commercial-squad",
            name: "Commercial Squad",
            thumnail:
                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/21-10-2021/commercial.jpg",

            description: `Commercial Squad focuses on expanding the reach of brand Talrop all over Kerala and systematically taking the products and services of Talrop's startups to the market and creating traction, thereby attracting venture capital funds, leading to global expansion.`,
        },
        {
            id: 3,
            link: "cyber-squad",
            name: "Cyber Squad",
            thumnail:
                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/21-10-2021/cyber.jpg",
            description: `Cyber Squad is a centralised IT department for developing digital platforms for Talrop's startups. It handles the entire IT-related aspects including design, development, testing, deployment, security etc. 
            `,
        },
        {
            id: 4,
            link: "startup-squad",
            name: "Startup Squad",
            thumnail:
                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/21-10-2021/startup.jpg",
            // "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/startup-squad.png",
            description: `Startup Squad is the department for handling and monitoring all the administrative and management parts of incubation and acceleration of startups. They deal with everything from infrastructure, finance, etc to administration, scaling and execution.`,
        },
    ];
    return (
        <Container>
            <LeftSection>
                <Title>Our Projects</Title>
                <Description>
                    To aid the development of a strong startup ecosystem in our state, Talrop has
                    designed four projects. They are Techies Park, Commercial Squad, Cyber Squad and
                    Startup Squad.
                </Description>
            </LeftSection>
            <RightSection>
                <CardSection>
                    {buldingBlocks.map((data) => (
                        <Cards key={data.id} id={data.link}>
                            <Thumnail>
                                <img src={data.thumnail} alt="" />
                            </Thumnail>
                            <CardTitle>{data.name}</CardTitle>
                            <CardDescription>{data.description}</CardDescription>
                        </Cards>
                    ))}
                </CardSection>
            </RightSection>
        </Container>
    );
}

export default FundamentBulding;

const Container = styled.div`
    margin: 120px 0 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media all and (max-width: 1280px) {
    }
    @media all and (max-width: 1100px) {
        grid-template-columns: 1fr;
        margin: 140px 0 60px;
    }
    @media all and (max-width: 768px) {
        margin: 120px 0 50px;
    }
    @media all and (max-width: 640px) {
        margin: 100px 0 0px;
    }
    @media all and (max-width: 480px) {
        margin: 80px 0 0;
    }
`;
const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const RightSection = styled.div``;
const Title = styled.h3`
    font-size: 34px;
    font-family: gordita_medium;
    color: #000;
    max-width: 400px;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        top: -30px;
        left: 0px;
        width: 100%;
        height: 300px;
        background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/backgrounds-color.png")
            no-repeat;
        background-size: contain;
        display: block;
        z-index: -1;
        opacity: 0.7;
    }
    @media all and (max-width: 1280px) {
        font-size: 32px;
    }
    @media all and (max-width: 1100px) {
        text-align: center;
        margin: 0 auto;
        font-size: 34px;
        &::before {
            height: 250px;
            top: -70px;
            left: 20%;
        }
    }
    @media all and (max-width: 768px) {
        font-size: 30px;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
        &::before {
            width: 200px;
            height: 300px;
        }
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
`;
const Description = styled.p`
    margin-top: 30px;
    max-width: 80%;
    @media all and (max-width: 1280px) {
        max-width: 85%;
    }
    @media all and (max-width: 1100px) {
        margin: 0 auto;
        margin-top: 40px;
        text-align: center;
    }
    @media all and (max-width: 768px) {
        margin-top: 25px;
        max-width: 90%;
    }
    @media all and (max-width: 640px) {
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        max-width: 100%;
        font-size: 14px;
    }
`;

const CardSection = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 35px;
    @media all and (max-width: 1280px) {
        grid-gap: 25px;
    }
    @media all and (max-width: 1100px) {
        max-width: 700px;
        margin: 0 auto;
        margin-top: 60px;
        grid-gap: 35px;
    }
    @media all and (max-width: 768px) {
        grid-gap: 25px;
    }
    @media all and (max-width: 640px) {
        grid-template-columns: 1fr;
    }
    @media all and (max-width: 360px) {
        margin-top: 50px;
    }
`;

const Cards = styled.div`
    padding: 20px;
    border: 1px solid #96ca4c5a;
    border-radius: 15px;
    padding-bottom: 30px;
    transition: all 0.3s ease-in-out;
    &:nth-child(odd) {
        transform: translateY(50px);
    }
    &:hover {
        border: 1px solid #0bbe61;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    }
    @media all and (max-width: 1280px) {
        padding: 15px;
        &:nth-child(odd) {
            transform: translateY(30px);
        }
    }
    @media all and (max-width: 1280px) {
        padding: 20px;
        &:nth-child(odd) {
            transform: translateY(50px);
        }
    }
    @media all and (max-width: 768px) {
        &:nth-child(odd) {
            transform: translateY(30px);
        }
    }
    @media all and (max-width: 640px) {
        &:nth-child(odd) {
            transform: translateY(0px);
        }
    }
`;

const Thumnail = styled.div`
    width: 100%;
    img {
        display: block;
        width: 100%;
    }
`;

const CardTitle = styled.h4`
    font-size: 18px;
    font-family: gordita_medium;
    color: #545454;
    margin-top: 20px;
`;
const CardDescription = styled.p`
    margin-top: 10px;
    font-size: 14px;
`;
