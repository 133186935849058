import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import OtpInput from "react-otp-input";
import ReCAPTCHA from "react-google-recaptcha";

//image-----
import CloseIcon from "../../../assets/images/technology-landing/close.svg";
import { manageConfig } from "../../../axiosConfig";
import ButtonLoader from "../loader/ButtonLoader";
import { useNavigate } from "react-router";

const NewOtpModal = ({
    isModal,
    setModal,
    number,
    selected,
    name,
    setName,
    setNumber,
    setFormModal,
    setSuccess,
}) => {
    const [otpVal, setOtpVal] = useState("");
    const [counter, setCounter] = useState(59);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [otp, setOtp] = useState(""); // _____________________
    const wrapperRef = useRef(null);
    const navigate = useNavigate();
    const recaptchaRef = useRef(null);
    const handleResend = async () => {
        if (counter === "resend") {
            setOtpVal("");
            const token = await recaptchaRef.current.executeAsync();
            manageConfig
                .post("technology-schools/resend/otp/", {
                    // name: name,
                    phone: number,
                    // district: district,
                    country: selected.web_code,
                    "g-recaptcha-response": token,
                })
                .then(function (res) {
                    const { StatusCode, data } = res.data;
                    if (StatusCode === 6000) {
                        setError(false);
                        setCounter(59);
                    } else {
                        setError(true);
                    }
                });
        }
    };

    const handleSubmit = () => {
        setLoading(true);

        manageConfig
            .post("technology-schools/enquiry/verify/phone/", {
                phone: number,
                country: selected.web_code,
                otp: otpVal,
                name: name,
            })
            .then(function (response) {
                const { StatusCode } = response.data;
                if (StatusCode === 6000) {
                    // setModal(false);
                    // setNumber("");
                    // setLoading(false);
                    // setFormModal(false);
                    navigate("/success/");
                    // setSuccess(true);
                } else {
                    setError(true);
                    setOtpVal("");
                    setLoading(false);
                }
            });
    };
    const handleUserKeyPress = (event) => {
        const { key, keyCode } = event;

        if (keyCode === 13) {
            handleSubmit();
        }
    };
    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);

        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    });
    useEffect(() => {
        const counterClock = () => {
            if (counter > 0) {
                counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
            } else if (counter === 0) {
                setCounter("resend");
            }
        };
        isModal && counterClock();
    }, [counter, isModal]);

    useEffect(() => {
        if ("OTPCredential" in window) {
            const ac = new AbortController();

            navigator.credentials
                .get({
                    otp: { transport: ["sms"] },
                    signal: ac.signal,
                })
                .then((otp) => {
                    setOtp({ otp: otp.code });
                    ac.abort();
                })
                .catch((err) => {
                    ac.abort();
                    console.log(err);
                });
        }
    }, []);

    return (
        <Cover>
            <Cover>
                {isModal ? (
                    <Overlay
                        style={{ display: isModal ? "block" : "none" }}
                    ></Overlay>
                ) : (
                    ""
                )}

                <BackContainer style={{ transform: isModal && "scale(1,1)" }}>
                    <Container ref={wrapperRef}>
                        {/* <Wrapper> */}
                        <Top>
                            <Left>Enter Verification Code</Left>
                            <Right onClick={() => setModal(false)}>
                                <img src={CloseIcon} alt="close" />
                            </Right>
                        </Top>
                        <Bottom>
                            <Content>
                                We have send you a verification code to{" "}
                                <NumberGreen>
                                    {selected.phone_code} {number}
                                </NumberGreen>
                            </Content>
                            <MiddleCover>
                                <OtpSection>
                                    <OtpInput
                                        numInputs={otp.code}
                                        placeholder={"0000"}
                                        inputStyle={
                                            error ? "error" : "OtpInput"
                                        }
                                        autocomplete="one-time-code"
                                        isInputNum={true}

                                        // value={otpVal}
                                        // onChange={(value) => setOtpVal(value)}
                                    />
                                    <ResendCode counter={counter}>
                                        Resend code?
                                        <span onClick={() => handleResend()}>
                                            {counter}
                                        </span>
                                    </ResendCode>
                                </OtpSection>
                            </MiddleCover>
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                //This ref can be used to call captcha related functions in case you need.
                                sitekey="6LdWOwMiAAAAAGY2aaaDt3dRgAMerfhPPY1RG6oc"
                                size="invisible"
                                badge="bottomleft"
                            />
                            <ButtonSect>
                                <VerifyButton onClick={() => handleSubmit()}>
                                    {isLoading ? <ButtonLoader /> : "Verify"}
                                </VerifyButton>
                            </ButtonSect>
                        </Bottom>
                        {/* </Wrapper> */}
                    </Container>
                </BackContainer>
            </Cover>
        </Cover>
    );
};

export default NewOtpModal;
const Cover = styled.div`
    width: 100%;
    height: 100%;
    //   background: #00000090;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
`;
const Overlay = styled.div`
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    position: fixed;
    z-index: 11;
    left: 0;
    top: 0px;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    filter: blur(1px);

    // position: absolute;
    // width: 100%;
    // height: 100%;
    // background: #00000060;
    // backdrop-filter: blur(4px);
    // display: ${({ isModal }) => (isModal ? "block" : "none")};
`;
const BackContainer = styled.div`
    position: fixed;
    transition: 0.3s;
    transform: scale(0, 0);
    width: 100%;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0px;
`;
const Container = styled.div`
    width: 35%;
    padding: 40px;
    background: #fff;
    border-radius: 10px;
    z-index: 1100;
    transition: 0.4s ease;
    transition-delay: 0.3s;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 1280px) {
        width: 50%;
    }
    @media (max-width: 768px) {
        width: 70%;
    }
    @media (max-width: 640px) {
        width: 90%;
    }

    // width: 90%;
    // max-width: 500px;
    // max-height: 100vh;
    // position: absolute;
    // margin: 0 auto;
    // left: 50%;
    // top: 50%;
    // transform: translate(-50%, -50%);
    // transition: 0.5s;
    // z-index: 101;
    // border: 1px solid #3b3b3b;
    // background: #1b1c1f;
    // border-radius: 5px;
    // overflow-y: hidden;
    // box-shadow: 0px 3px 56px #00000048;
`;
const Wrapper = styled.div`
    width: 85%;
    margin: 0 auto;
`;
const Top = styled.div`
    // padding: 25px 0px;
    padding-bottom: 25px;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
`;
const Left = styled.h5`
    font-size: 24px;
    font-family: gordita_medium;
    @media (max-width: 840px) {
        font-size: 20px;
    }
    @media (max-width: 480px) {
        font-size: 20px;
    }
    @media (max-width: 420px) {
        right: 18px;
    }
    @media (max-width: 360px) {
        font-size: 15px;
    }
`;
const Right = styled.div`
    cursor: pointer;
    width: 25px;
    img {
        width: 100%;
    }
    @media (max-width: 840px) {
        width: 20px;
    }
    @media (max-width: 360px) {
        width: 15px;
    }
`;
const Bottom = styled.div`
    padding: 25px 0px;
    // @media (max-width: 480px) {
    //     padding-bottom: 70px;
    // }
`;
const Content = styled.h5`
    font-size: 16px;

    @media (max-width: 420px) {
        font-size: 14px;
    }
`;
const NumberGreen = styled.h5`
    display: inline;
    font-size: 16px;
    color: #0fa76f;
    @media (max-width: 420px) {
        font-size: 14px;
    }
`;
const MiddleCover = styled.div`
    // position: relative;
`;
const OtpSection = styled.div`
    width: 64%;
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    &.OtpInput {
        width: 100px;
        height: 40px;
        border: red;
    }
    @media (max-width: 1080px) {
        width: 80%;
    }
    @media (max-width: 980px) {
        width: 90%;
    }
    @media (max-width: 840px) {
        width: 90%;
    }
    @media (max-width: 640px) {
        width: 80%;
    }
    @media (max-width: 480px) {
        width: 90%;
    }
    @media (max-width: 420px) {
        width: 94%;
    }
    @media (max-width: 480px) {
        margin-top: 20px;
    }
`;
const ResendCode = styled.h5`
    // text-align: right;
    position: absolute;
    bottom: -25px;
    right: -25px;
    margin-top: 15px;
    font-size: 14px;
    span {
        color: #0fa76f;
        margin-left: 5px;
        font-size: 14px;
        text-transform: capitalize;
        cursor: ${({ counter }) =>
            counter === "resend" ? "pointer" : "not-allowed"};
    }
    @media (max-width: 540px) {
        right: 13px;
    }
`;
const ButtonSect = styled.div`
    margin-top: 65px;
    margin-bottom: 50px;
    @media (max-width: 480px) {
        margin-top: 25px;
    }
    @media (max-width: 540px) {
        margin-top: 40px;
    }
`;
const VerifyButton = styled.div`
    width: 150px;
    height: 45px;
    background: #0fa76f;
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    font-family: gordita_medium;
    float: right;
`;
