import React from "react";
import styled from "styled-components";
import Header from "./Header";

export default function WhyStartups() {
    const datas = [
        {
            id: 1,
            title: "Our Mission",
            content:
                "Talrop is on a mission to create a strong startup ecosystem in Kerala by building 140 tech startups and systematically guiding them in every stage, from idea validation to funding and scaling. We hope to bring forth the most innovative ideas from talented young minds and pave a path for products, services and manpower from our state to the international market thereby increasing the inflow of foreign funds and promoting the economic growth of our state.",
        },
        {
            id: 2,
            title: "Our Vision",
            content:
                "Building a strong startup ecosystem in our state can solve a lot of problems that we currently face. Because there is rarely any problem that new ideas and innovations cannot solve. We believe that creating those solutions will accelerate our economic growth, alongside ensuring the overall well-being of our state as a whole.",
        },
        {
            id: 3,
            title: "Our Values",
            content:
                "We uphold equality of opportunity for all as our core value. We believe that the growth of a society is possible only when everyone irrespective of class, caste, creed, gender, race, etc. grow together. Everyone should have jobs that fit their true potential and that guide them to financial freedom and security.",
        },
    ];

    return (
        <>
            <Header />
            <MainContainer className="wrapper">
                <Container id="why-startups" className="spotlight">
                    <Left data-aos="fade-right">
                        <Heading>
                            <b>We</b> are here <br /> to make a revolution in{" "}
                            <span>Kerala</span>
                        </Heading>
                        {datas.map((item) => (
                            <Contents key={item.id}>
                                <Label>{item.title}</Label>
                                <Content>{item.content}</Content>
                            </Contents>
                        ))}
                    </Left>
                    <Right data-aos="fade-left">
                        {/* <Heading className="responsivehead">
                            We are here to make a revolution in Kerala
                        </Heading> */}
                        <PremiumContainer>
                            <LogoContainer>
                                <LogoLink
                                    target="_blank"
                                    href="https://talrop.com/"
                                >
                                    <Logo
                                        src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/17-02-2022/talrop-logo.png"
                                        alt="Logo"
                                    />
                                </LogoLink>
                            </LogoContainer>
                            <Premium>
                                Building a strong startup ecosystem in Kerala
                            </Premium>
                            <Content className="premium">
                                <Break>
                                    Talrop is a company based in Cochin since
                                    2017, working towards building a strong
                                    startup ecosystem in Kerala through
                                    establishing 140 highly scalable startups
                                    from Kerala.
                                </Break>
                                {/* <br /> */}
                                {/* <br /> */}
                                <Break>
                                    Paving a path for our products to reach
                                    international market, channelling all
                                    services and projects to our state,
                                    providing manpower to other countries and
                                    landing venture capital funds to our state,
                                    are Talrop’s objectives to solve
                                    unemployment, poverty and hunger.
                                </Break>
                                {/* <br /> */}
                                {/* <br /> */}
                                <Break>
                                    Education, Technolgy, Jobs and
                                    Entrepreneurship are the four pillars of
                                    Talrop’s startup ecosystem.
                                </Break>
                                {/* <br /> */}
                                {/* <br /> */} <Break></Break>
                                {/* <br /> */}
                                {/* <br /> */} <Break></Break>
                            </Content>
                        </PremiumContainer>
                    </Right>
                </Container>
            </MainContainer>
        </>
    );
}
const MainContainer = styled.div``;
const Container = styled.div`
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    @media all and (max-width: 980px) {
        flex-direction: column-reverse;
    }
    @media all and (max-width: 768px) {
        padding-top: 95px;
        margin-bottom: 45px;
    }
    @media all and (max-width: 640px) {
        padding-top: 50px;
        margin-bottom: 25px;
    }
`;
const Left = styled.div`
    position: relative;
    width: 49%;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const Heading = styled.h2`
    font-size: 38px;
    font-family: gordita_medium;
    color: #212121;
    position: relative;
    margin-bottom: 54px;
    letter-spacing: 0.41px;
    @media all and (max-width: 1380px) {
        font-size: 33px;
    }
    @media all and (max-width: 1200px) {
        font-size: 29px;
    }
    @media all and (max-width: 1080px) {
        font-size: 26px;
    }
    @media all and (max-width: 980px) {
        font-size: 28px;
    }
    @media all and (max-width: 640px) {
        font-size: 26px;
    }
    @media all and (max-width: 480px) {
        font-size: 18px;
    }
    @media all and (max-width: 360px) {
        font-size: 17px;
    }

    b {
        color: #0bbe61;
        font-family: gordita_bold;
    }
    & span {
        position: relative;
    }

    & span::before {
        content: "";
        background: url(${"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/underline-green.svg"});
        background-repeat: no-repeat;
        position: absolute;
        background-size: 100%;
        width: 180px;
        left: -48px;
        height: 15px;
        bottom: -10px;
        @media all and (max-width: 1080px) {
            width: 180px;
            left: -48px;
        }
        @media all and (max-width: 640px) {
            width: 140px;
            left: -38px;
        }
        @media all and (max-width: 480px) {
            width: 99px;
            left: -31px;
        }
    }
    &.responsivehead {
        display: none;
        @media all and (max-width: 980px) {
            display: block;
            text-align: center;
        }

        @media all and (max-width: 480px) {
            font-size: 22px;
            margin-bottom: 27px;
        }
    }
`;

const Contents = styled.div`
    margin-bottom: 63px;
    &:last-child {
        margin-bottom: 0px;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 25px;
    }
`;
const Label = styled.div`
    margin-bottom: 10px;
    font-family: "gordita_medium";
    font-size: 20px;
    @media all and (max-width: 640px) {
        font-size: 16px;
    }
`;
const Content = styled.p`
    line-height: 1.8em;
    font-size: 16px;
    color: #757474;
    font-family: gordita_regular;
    &.premium {
        font-size: 15px;
        margin-top: 20px;
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const Right = styled.div`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 40%;
    @media all and (max-width: 980px) {
        width: 100%;
        align-items: center;
        margin-bottom: 60px;
    }
`;
const PremiumContainer = styled.div`
    position: -webkit-sticky;
    position: sticky;
    top: 113px;
    width: 450px;
    padding: 35px;
    border: 1px solid #e2e2e2;
    border-radius: 15px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media all and (max-width: 1100px) {
        width: 344px;
    }
    @media all and (max-width: 980px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        padding: 18px 15px;
    }
`;
const LogoContainer = styled.h1`
    margin-bottom: 6px;
`;
const LogoLink = styled.a`
    width: 82px;
    display: block;
    @media all and (max-width: 480px) {
        width: 67px;
    }
`;
const Logo = styled.img`
    width: 100%;
    display: block;
`;
const Premium = styled.div`
    display: flex;
    font-size: 16px;
    font-family: "gordita_medium";
    margin-top: 20px;
    text-align: left;
    width: 100%;
    &:before {
        content: "";
        width: 4px;
        height: 20px;
        background: #1bca1b;
        margin-right: 5px;
    }
    @media all and (max-width: 640px) {
        font-size: 16px;
    }
`;

const Break = styled.span`
    display: block;
    margin-bottom: 16px;
`;
