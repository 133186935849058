import React, { useEffect } from "react";
import CommunityMissions from "./new-landing/CommunityMissions";
import Header from "./new-landing/Header";
import InternalSquad from "./new-landing/InternalSquad";
import OurProjects from "./new-landing/OurProjects";
import Footer from "./new-landing/Footer";
import EcosystemSpot from "./new-landing/EcosystemSpot";

export default function NewEcosystemPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Header />
            <EcosystemSpot />
            <InternalSquad />
            <OurProjects />
            <CommunityMissions />
            <Footer />
        </>
    );
}
