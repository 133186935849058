import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { manageConfig } from "../../../axiosConfig";
import ButtonLoader from "../loader/ButtonLoader";
import CountrySelector from "./CountrySelector";
import NewOtpModal from "./NewOtpModal";
import { useSearchParams } from "react-router-dom";
import validator from "validator";
import ReCAPTCHA from "react-google-recaptcha";

const NewFormModal = ({
    formModal,
    setFormModal,
    number,
    selected,
    setSelected,
    setModal,
    setNumber,
    isModal,
    name,
    setName,
    setCountry,
}) => {
    const [code, setCode] = useState(false);
    const [email, setEmail] = useState("");
    const [district, setDistrict] = useState("");
    //   const [districts,setDistricts]=useState('')
    const [districtError, setDistrictError] = useState(false);
    const [active, setActive] = useState("");
    const [isDropdown, setDropdown] = useState(false);
    const [education, setEducation] = useState("");
    const [institution, setInstitution] = useState("");
    const [isError, setError] = useState(false);
    const [year, setYear] = useState("");
    const [gender, setGender] = useState("");
    const [response, setResponse] = useState("");
    const wrapperRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState("This field is required");
    const [isEmail, setEmailErr] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const refer = searchParams.get("r");
    const recaptchaRef = useRef(null);

    // console.log(refer);

    //   console.log(isDropdown);
    const districts = [
        { id: 1, dist: "Thiruvananthapuram" },
        { id: 2, dist: "Kollam" },
        { id: 3, dist: "Pathanamthitta" },
        { id: 4, dist: "Alappuzha" },
        { id: 5, dist: "Kottayam" },
        { id: 6, dist: "Idukki" },
        { id: 7, dist: "Ernakulam" },
        { id: 8, dist: "Thrissur" },
        { id: 9, dist: "Palakkad" },
        { id: 10, dist: "Malappuram" },
        { id: 11, dist: "Kozhikode" },
        { id: 12, dist: "Wayanad" },
        { id: 13, dist: "Kannur" },
        { id: 14, dist: "Kasaragod" },
    ];

    const currentYear = new Date().getFullYear();
    const handleEmail = () => {
        if (email != "") {
            if (validator.isEmail(email)) {
                setEmailError("");
                setEmailErr(false);
            } else {
                setEmailErr(true);
                setEmailError("Enter valid email");
            }
        } else {
            setEmailErr(true);
            setEmailError("This field is required");
        }
    };

    const onFormSubmit = async () => {
        setError(true);
        setLoading(true);
        handleEmail();
        const token = await recaptchaRef.current.executeAsync();
        manageConfig
            .post(`technology-schools/enquiry/registration/`, {
                name: name,
                phone: number,
                district: district.toLocaleLowerCase(),
                country: selected.web_code,
                email: email,
                gender: gender,
                educational_qualification: education,
                name_of_institution: institution,
                year_of_completion: year,
                referral_code: refer,
                "g-recaptcha-response": token,
            })
            .then(function (res) {
                const { StatusCode, data } = res.data;
                if (StatusCode === 6000) {
                    setError(false);
                    setModal(true);
                    setLoading(false);
                }
                if (StatusCode === 6001) {
                    setLoading(false);
                    setResponse(res.data.data.title);
                    if (res.data.data.title === "Failed") {
                        setResponse(res.data.data.message);
                    }
                }
            });
    };
    const handleGender = (e) => {
        setGender(e.target.value);
    };
    // console.log(response);
    return (
        <>
            <Cover formModal={formModal}>
                <Container formModal={formModal}>
                    <Top>
                        <Left>
                            <h4>Enter details</h4>
                        </Left>
                        <CloseContainer onClick={() => setFormModal(false)}>
                            <img
                                src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-04-2022/close.png"
                                alt="image"
                            />
                        </CloseContainer>
                    </Top>
                    <Bottom>
                        <form>
                            <InputContainer>
                                <Title>Name*</Title>
                                <InputFields>
                                    <input
                                        type="text"
                                        placeholder="Enter your name"
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                        value={name}
                                    />
                                </InputFields>
                                {isError === true && name === "" ? (
                                    <ErrorMessage>
                                        This field is required
                                    </ErrorMessage>
                                ) : (
                                    ""
                                )}
                            </InputContainer>
                            <InputContainer>
                                <Title>Phone Number*</Title>
                                <InputFields>
                                    <LeftFlag onClick={() => setCode(!code)}>
                                        <ImageCont>
                                            <img
                                                src={selected.flag}
                                                alt="flag"
                                            />
                                        </ImageCont>
                                        <Code>{selected.phone_code}</Code>
                                    </LeftFlag>
                                    <input
                                        type="number"
                                        placeholder="Enter your number"
                                        value={number}
                                        onChange={(e) =>
                                            setNumber(e.target.value)
                                        }
                                    />
                                </InputFields>
                                {isError === true && number === "" ? (
                                    <ErrorMessage>
                                        This field is required
                                    </ErrorMessage>
                                ) : (
                                    ""
                                )}
                            </InputContainer>
                            <InputContainer>
                                <Title>Email Address*</Title>
                                <InputFields>
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        value={email}
                                    />
                                </InputFields>
                                {isEmail ? (
                                    <ErrorMessage>{emailError}</ErrorMessage>
                                ) : (
                                    ""
                                )}
                            </InputContainer>
                            <InputContainer>
                                <Title>Gender*</Title>
                                <InputFields className="gendercontainer">
                                    <CoverCont>
                                        <input
                                            type="radio"
                                            placeholder="Enter your name"
                                            className="gender"
                                            value="male"
                                            id="male"
                                            name="gender"
                                            onChange={(e) => handleGender(e)}
                                        />
                                        <label for="male">Male</label>
                                    </CoverCont>
                                    <CoverCont>
                                        <input
                                            type="radio"
                                            placeholder="Enter your name"
                                            className="gender"
                                            value="female"
                                            id="female"
                                            name="gender"
                                            onChange={(e) => handleGender(e)}
                                        />
                                        <label for="female">Female</label>
                                    </CoverCont>
                                    <CoverCont>
                                        <input
                                            type="radio"
                                            placeholder="Enter your name"
                                            className="gender"
                                            value="other"
                                            id="other"
                                            name="gender"
                                            onChange={(e) => handleGender(e)}
                                        />
                                        <label for="other">Other</label>
                                    </CoverCont>
                                </InputFields>
                                {isError === true && gender === "" ? (
                                    <ErrorMessage>
                                        This field is required
                                    </ErrorMessage>
                                ) : (
                                    ""
                                )}
                            </InputContainer>
                            <InputContainer>
                                <Title>District*</Title>
                                <InputFields
                                    onClick={() => setDropdown(!isDropdown)}
                                >
                                    <DistrictContainer district={district}>
                                        {district
                                            ? district
                                            : "Select district"}
                                    </DistrictContainer>
                                    <DownArrow active={active}>
                                        <img
                                            src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/13-06-2022/down-arrow.svg"
                                            alt="Down Arrow"
                                        />
                                    </DownArrow>
                                    <Dropdown
                                        isDropdown={isDropdown}
                                        district={district}
                                        ref={wrapperRef}
                                    >
                                        {districts.map((dist) => (
                                            <div
                                                key={dist.dist}
                                                onClick={() => {
                                                    setDistrict(dist.dist);
                                                    setActive("");
                                                    setDropdown(!isDropdown);
                                                    setDistrictError("");
                                                }}
                                            >
                                                {dist.dist}
                                            </div>
                                        ))}
                                    </Dropdown>
                                </InputFields>
                                {isError === true && district === "" ? (
                                    <ErrorMessage>
                                        This field is required
                                    </ErrorMessage>
                                ) : (
                                    ""
                                )}
                            </InputContainer>
                            <InputContainer>
                                <Title>Educational Qualification*</Title>
                                <InputFields>
                                    <input
                                        type="text"
                                        placeholder="Enter your educational qualification"
                                        value={education}
                                        onChange={(e) =>
                                            setEducation(e.target.value)
                                        }
                                    />
                                </InputFields>
                                {isError === true && education === "" ? (
                                    <ErrorMessage>
                                        This field is required
                                    </ErrorMessage>
                                ) : (
                                    ""
                                )}
                            </InputContainer>
                            <InputContainer>
                                <Title>Name of School / College*</Title>
                                <InputFields>
                                    <input
                                        type="text"
                                        placeholder="Enter the name of school / college"
                                        onChange={(e) =>
                                            setInstitution(e.target.value)
                                        }
                                        value={institution}
                                    />
                                </InputFields>
                                {isError === true && institution === "" ? (
                                    <ErrorMessage>
                                        This field is required
                                    </ErrorMessage>
                                ) : (
                                    ""
                                )}
                            </InputContainer>
                            <InputContainer>
                                <Title>Year of Completion*</Title>
                                <InputFields>
                                    <input
                                        type="number"
                                        placeholder="Y Y Y Y"
                                        onChange={(e) =>
                                            setYear(e.target.value)
                                        }
                                        value={year}
                                        maxLength="4"
                                    />
                                </InputFields>
                                {isError === true &&
                                !(year >= 1980 && year <= currentYear) ? (
                                    <ErrorMessage>
                                        Enter a valid year
                                    </ErrorMessage>
                                ) : (
                                    ""
                                )}
                            </InputContainer>
                        </form>
                        <ButtonSect>
                            {response !== "" ? (
                                <Response>
                                    {response === "Validation Error"
                                        ? ""
                                        : response}
                                </Response>
                            ) : (
                                ""
                            )}
                            {loading ? (
                                <Loader>
                                    <ButtonLoader />
                                </Loader>
                            ) : (
                                <input
                                    type="submit"
                                    value="Continue"
                                    onClick={() => {
                                        onFormSubmit();
                                    }}
                                />
                            )}
                        </ButtonSect>
                    </Bottom>
                </Container>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    //This ref can be used to call captcha related functions in case you need.
                    sitekey="6LdWOwMiAAAAAGY2aaaDt3dRgAMerfhPPY1RG6oc"
                    size="invisible"
                    badge="bottomleft"
                />
            </Cover>
            <CountrySelector
                selected={selected}
                setSelected={setSelected}
                code={code}
                setCode={setCode}
                setCountry={setCountry}
            />
        </>
    );
};

export default NewFormModal;
const Loader = styled.div`
    width: 48%;
    height: 45px;
    background: #0fa76f;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    float: right;
    font-size: 15px;
    display: flex;
    align-items: center;
`;
const Cover = styled.div`
    height: 100vh;
    width: 100%;
    display: ${({ formModal }) => (formModal ? "block" : "none")};
    background: #00000090;
    position: fixed;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const CoverCont = styled.div`
    display: flex;
    label {
        font-size: 14px;
        margin-right: 15px;
        cursor: pointer;
        @media (max-width: 420px) {
            font-size: 12px;
            margin-right: 10px;
        }
    }
    input {
        margin-right: 5px;
        font-size: 15px;
        cursor: pointer;
    }
`;
const Container = styled.div`
    background: #fff;
    padding: 20px 40px;
    width: 60%;
    border-radius: 10px;
    transform: ${({ formModal }) => (formModal ? "scale(1)" : "scale(0)")};
    // max-height: 600px;
    overflow-y: scroll;
    //   padding-bottom:40px;
    @media (max-width: 1080px) {
        width: 60%;
    }
    @media (max-width: 640px) {
        max-height: 550px;
    }
    @media (max-width: 980px) {
        width: 75%;
    }
    @media (max-width: 768px) {
        width: 85%;
    }
    @media (max-width: 360px) {
        padding: 20px 20px;
    }

    &::-webkit-scrollbar {
        display: none;
    }
`;
const Dropdown = styled.div`
    min-width: 280px;
    width: 100%;
    max-height: 180px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
    border: 1px solid #e0e0e0;
    display: ${({ isDropdown }) => (isDropdown ? "block" : "none")};
    transition: all 0.5s ease;
    position: absolute;
    box-shadow: 0px 5px 31px -7px rgba(119, 122, 119, 0.98);
    border-radius: 8px;
    background: #fff;
    top: 100%;
    z-index: 10;
    div {
        padding: 15px 30px;
        font-size: 13px;
        font-family: gordita_regular;
        cursor: pointer;
    }
`;
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 0px;
    position: relative;
    &:after {
        content: "";
        width: 100%;
        height: 2px;
        background: #e7e7e7;
        position: absolute;
        bottom: 0;
    }
`;
const Code = styled.div`
    font-size: 15px;
    color: #000;
    @media (max-width: 640px) {
    }
`;
const DownArrow = styled.div`
    width: 22px;
    transform: ${({ active }) =>
        active === "dist" ? "rotate(180deg)" : "rotate(0deg)"};
    transition: all 0.5s ease;
    margin-right: 10px;
    img {
        display: block;
        width: 100%;
    }
`;
const Left = styled.span`
    h4 {
        font-size: 22px;
        font-family: gordita_medium;
    }
`;
const CloseContainer = styled.div`
    cursor: pointer;
`;
const Bottom = styled.div`
    margin-top: 10px;
    form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;
const InputContainer = styled.div`
    width: 48%;
    margin-top: 25px;
    position: relative;
    cursor: pointer;
    @media (max-width: 640px) {
        width: 100%;
    }
`;
const Title = styled.h4`
    font-size: 13px;
    color: #6b6b6b;
    margin-bottom: 5px;
`;
const InputFields = styled.div`
    // width:48%;
    width: 100%;
    height: 48px;
    border: 1px solid #e7e7e7;
    border-radius: 6px;
    display: flex;
    align-items: center;
    position: relative;
    &.gendercontainer {
        border: 1px solid #e7e7e7;
        padding-left: 8px;
        // display:
    }
    //   label {
    //     color: #afafaf;
    //     margin-left: 10px;
    //   }
    input {
        width: 100%;
        height: 100%;
        padding: 11px;
        font-size: 15px;
        &::placeholder {
            font-size: 13px;
        }
        &.gender {
            width: 20px;
            height: 20px;
            @media (max-width: 420px) {
                width: 15px;
                height: 15px;
            }
            //   margin-left: 30px;
        }
    }
`;
const DistrictContainer = styled.div`
    cursor: pointer;
    font-size: 13px;
    font-family: gordita_regular;
    width: 100%;
    padding: 14px 11px;
    color: ${({ district }) => (district ? "#000" : "#9b9a9a")};
    @media all and (max-width: 1080px) {
        font-size: 16px;
    }
    @media all and (max-width: 540px) {
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        font-size: 12px;
        padding: 10px 15px;
    }
`;
const ButtonSect = styled.div`
    width: 100%;
    margin-top: 45px;
    padding-bottom: 70px;
    position: relative;
    input {
        width: 48%;
        height: 45px;
        background: #0fa76f;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
        float: right;
        font-size: 15px;
    }
`;
const LeftFlag = styled.div`
    display: flex;
    margin-left: 8px;
    // width: 20%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    @media (max-width: 1280px) {
        width: 26%;
    }
    @media (max-width: 640px) {
        width: 15%;
    }
    @media (max-width: 480px) {
        width: 20%;
    }
`;
const ImageCont = styled.div`
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 5px;
    @media (max-width: 640px) {
        width: 20px;
        height: 20px;
    }
    img {
        width: 150%;
        display: block;
        object-fit: contain;
        transform: translateX(-8px);
        @media (max-width: 640px) {
            transform: translateX(-5px);
        }
    }
`;
const ErrorMessage = styled.p`
    color: red;
    position: absolute;
    right: 0;
    bottom: -25px;
    font-size: 12px;
`;
const Response = styled.p`
    color: red;
    position: absolute;
    right: 0;
    top: -20px;
    font-size: 12px;
`;
