import React from "react";
import styled from "styled-components";

export default function WhyStartups() {
    const datas = [
        {
            id: 1,
            title: "Our Mission",
            content:
                "Talrop is on a mission to create a strong startup ecosystem in Kerala by building 140 tech startups and systematically guiding them in every stage, from idea validation to funding and scaling. We hope to bring forth the most innovative ideas from talented young minds and pave a path for products, services and manpower from our state to the international market, thereby increasing the inflow of foreign funds and promoting the economic growth of our state.",
        },
        {
            id: 2,
            title: "Our Vision",
            content:
                "Building a strong startup ecosystem in our state can solve a lot of problems that we currently face. Because there is rarely any problem that new ideas and innovations can't solve. We believe that creating those solutions will accelerate our economic growth, alongside ensuring the overall well-being of our state as a whole.",
        },
        {
            id: 3,
            title: "Our Values",
            content:
                "We uphold equality of opportunity for all as our core value. We believe that the growth of a society is possible only when everyone irrespective of class, caste, creed, gender, race, etc grow together. Everyone should have jobs that fit their true potential and that guide them to financial freedom and security.",
        },
    ];

    return (
        <MainContainer className="wrapper">
            <Container id="why-startups" className="spotlight">
                <Left data-aos="fade-right">
                    <Heading>
                        We are here <br /> to make a revolution in Kerala
                    </Heading>
                    {datas.map((item) => (
                        <Contents key={item.id}>
                            <Label>{item.title}</Label>
                            <Content>{item.content}</Content>
                        </Contents>
                    ))}
                </Left>
                <Right data-aos="fade-left">
                    <Heading className="responsivehead">
                        We are here to make a revolution in Kerala
                    </Heading>
                    <PremiumContainer>
                        <LogoContainer>
                            <LogoLink
                                target="_blank"
                                href="https://talrop.com/"
                            >
                                <Logo
                                    src="https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/17-02-2022/talrop-logo.png"
                                    alt="Logo"
                                />
                            </LogoLink>
                        </LogoContainer>
                        <Premium>
                            Creating a strong startup ecosystem in Kerala
                        </Premium>
                        <Content className="premium">
                            <Break>
                                Talrop is a company working towards building a
                                strong startup ecosystem in Kerala.
                            </Break>
                            {/* <br /> */}
                            {/* <br /> */}
                            <Break>
                                The foundation for this is built by providing
                                the right education and integrating it with
                                technology and making maximum use of it.
                            </Break>
                            {/* <br /> */}
                            {/* <br /> */}
                            <Break>
                                We mould entrepreneurs and help build startups
                                from scratch and take them to a highly scalable
                                level.
                            </Break>
                            {/* <br /> */}
                            {/* <br /> */}{" "}
                            <Break>
                                We hope to pave a path for products, services,
                                and manpower from our state, to the
                                international market, thereby solving the
                                problem of unemployment, once and forever.
                            </Break>
                            {/* <br /> */}
                            {/* <br /> */}{" "}
                            <Break>
                                This will also increase the inflow of foreign
                                funds to our state, which can be reinvested for
                                the development of education, infrastructure,
                                etc, ensuring overall development and upliftment
                                of our society.
                            </Break>
                        </Content>
                    </PremiumContainer>
                </Right>
            </Container>
        </MainContainer>
    );
}
const MainContainer = styled.div``;
const Container = styled.div`
    padding-top: 114px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
    @media all and (max-width: 980px) {
        flex-direction: column-reverse;
    }
    @media all and (max-width: 768px) {
        padding-top: 95px;
        margin-bottom: 45px;
    }
    @media all and (max-width: 640px) {
        padding-top: 50px;
        margin-bottom: 25px;
    }
`;
const Left = styled.div`
    position: relative;
    width: 49%;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const Heading = styled.h2`
    font-size: 34px;
    font-family: gordita_medium;
    color: #212121;
    position: relative;
    margin-bottom: 54px;
    letter-spacing: 0.41px;
    &.responsivehead {
        display: none;
        @media all and (max-width: 980px) {
            display: block;
            text-align: center;
        }

        @media all and (max-width: 480px) {
            font-size: 23px;
            margin-bottom: 27px;
        }
    }
    &::before {
        content: "";
        position: absolute;
        top: -86px;
        left: -70px;
        width: 273px;
        height: 258px;
        background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/backgrounds-color.png")
            no-repeat;
        background-size: contain;
        display: block;
        z-index: -1;
        @media all and (max-width: 768px) {
            top: -69px;
            left: -42px;
            width: 300px;
            height: 200px;
        }
        @media all and (max-width: 640px) {
            top: -31px;
            left: -42px;
            width: 268px;
            height: 161px;
        }
        @media all and (max-width: 480px) {
            top: -23px;
            left: -4px;
            width: 136px;
            height: 129px;
        }
    }
    @media all and (max-width: 1200px) {
        font-size: 27px;
    }
    @media all and (max-width: 980px) {
        display: none;
    }
`;

const Contents = styled.div`
    margin-bottom: 63px;
    &:last-child {
        margin-bottom: 0px;
    }
    @media all and (max-width: 480px) {
        margin-bottom: 25px;
    }
`;
const Label = styled.div`
    margin-bottom: 10px;
    font-family: "gordita_medium";
    font-size: 20px;
    @media all and (max-width: 640px) {
        font-size: 16px;
    }
`;
const Content = styled.p`
    line-height: 1.8em;
    font-size: 16px;
    color: #757474;
    font-family: gordita_regular;
    &.premium {
        font-size: 15px;
        margin-top: 12px;
        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;
const Right = styled.div`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    width: 40%;
    @media all and (max-width: 980px) {
        width: 100%;
        align-items: center;
        margin-bottom: 60px;
    }
`;
const PremiumContainer = styled.div`
    position: -webkit-sticky;
    position: sticky;
    top: 113px;
    width: 450px;
    padding: 35px;
    border: 1px solid #e2e2e2;
    border-radius: 15px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media all and (max-width: 1100px) {
        width: 344px;
    }
    @media all and (max-width: 980px) {
        width: 100%;
    }
    @media all and (max-width: 480px) {
        padding: 18px 15px;
    }
`;
const LogoContainer = styled.h1`
    margin-bottom: 6px;
`;
const LogoLink = styled.a`
    width: 82px;
    display: block;
    @media all and (max-width: 480px) {
        width: 67px;
    }
`;
const Logo = styled.img`
    width: 100%;
    display: block;
`;
const Premium = styled.div`
    display: flex;
    font-size: 16px;
    font-family: "gordita_medium";
    margin-top: 20px;
    text-align: left;
    width: 100%;
    &:before {
        content: "";
        width: 4px;
        height: 20px;
        background: #1bca1b;
        margin-right: 5px;
    }
    @media all and (max-width: 640px) {
        font-size: 16px;
    }
`;

const Break = styled.span`
    display: block;
    margin-bottom: 16px;
`;
