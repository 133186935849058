import React from "react";
import styled from "styled-components";

function DiuuSpotlight() {
    return (
        <Container>
            <section className="wrapper">
                <LeftSection data-aos="fade-right">
                    <NumberContainer>
                        <img
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/05-09-2022/09_Diuu.svg"
                            }
                            alt="Number01"
                        />
                    </NumberContainer>
                    <Title>
                        <span>Diuu </span> - A <b>Marketplace</b>
                        <br />
                        for Digital Universe
                    </Title>
                    <Description>
                        Diuu creates a marketplace for local community, to
                        bridge Indian products to the international market, and
                        to become a metaverse marketplace in the future.
                    </Description>
                    <ImageContainer>
                        <img
                            src={
                                "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/DiuSpotight.png"
                            }
                            alt="Startups"
                        />
                    </ImageContainer>
                    <Button href="http://www.diuu.ai/" target="_blank">
                        Visit Website
                    </Button>
                </LeftSection>
                <RightSection data-aos="fade-left">
                    <img
                        src={
                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/DiuSpotight.png"
                        }
                        alt="Startups"
                    />
                </RightSection>
            </section>
        </Container>
    );
}

export default DiuuSpotlight;

const Container = styled.section`
    padding: 100px 0px;
    background: #fafafa;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media all and (max-width: 980px) {
        flex-direction: column;
        padding: 40px 0px;
    }
    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media all and (max-width: 980px) {
            flex-direction: column;
        }
    }
`;
const LeftSection = styled.div`
    width: 50%;
    position: relative;
    order: 1;
    @media all and (max-width: 980px) {
        width: 100%;
    }
`;
const NumberContainer = styled.div`
    padding: 5px 8px;
    width: 25%;
    border-radius: 5px;
    @media all and (max-width: 980px) {
        width: 20%;
    }
    @media all and (max-width: 640px) {
        width: 30%;
    }
`;
const Title = styled.h1`
    font-size: 40px;
    margin-top: 30px;
    font-family: gordita_medium;
    color: #212121;
    margin-bottom: 20px;

    & span {
        position: relative;
        font-size: 40px;
        @media all and (max-width: 1280px) {
            font-size: 35px;
        }
        @media all and (max-width: 768px) {
            font-size: 33px;
        }
        @media all and (max-width: 480px) {
            font-size: 26px;
        }
    }

    & span::before {
        content: "";
        background: url(${"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/UnderLineDiu.png"});
        background-repeat: no-repeat;
        position: absolute;
        background-size: 100%;
        width: 100%;
        left: 0;
        height: 15px;
        bottom: -10px;
    }

    @media all and (max-width: 1440px) {
        font-size: 35px;
    }
    @media all and (max-width: 1280px) {
        font-size: 32px;
        margin-top: 20px;
    }
    @media all and (max-width: 980px) {
        font-size: 30px;
        margin-top: 20px;
    }
    @media all and (max-width: 768px) {
        font-size: 26px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
    }
    b {
        color: #ebb800;
        font-size: 50px;
        @media all and (max-width: 768px) {
            font-size: 36px;
        }
        @media all and (max-width: 1440px) {
            font-size: 40px;
        }
        @media all and (max-width: 980px) {
            font-size: 34px;
            margin-top: 20px;
        }
        @media all and (max-width: 768px) {
            font-size: 26px;
        }
    }
`;
const Description = styled.p`
    font-size: 18px;
    margin-bottom: 30px;

    @media all and (max-width: 980px) {
        font-size: 17px;
    }
    @media all and (max-width: 768px) {
        font-size: 16px;
    }
`;
const Button = styled.a`
    background: #ffcc01;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    width: 200px;
    font-size: 18px;
    color: #1e1e1e;
    font-family: "gordita_regular";

    @media all and (max-width: 1280px) {
        font-size: 16px;
        width: 180px;
    }
    @media all and (max-width: 768px) {
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        width: 150px;
        font-size: 14px;
    }
    @media all and (max-width: 360px) {
        width: 140px;
        font-size: 13px;
    }
`;
const RightSection = styled.div`
    width: 45%;
    order: 2;
    @media all and (max-width: 980px) {
        width: 60%;
        margin-top: 30px;
        display: none;
    }
`;
const ImageContainer = styled.div`
    width: 45%;
    order: 2;
    display: none;
    @media all and (max-width: 980px) {
        width: 60%;
        margin: 30px auto;
        display: block;
    }
`;
