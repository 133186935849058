import React, { useState } from "react";
import styled from "styled-components";
import bg from "../../../assets/images/new-landing/mission-bg.svg";

export default function OurProjects() {
    const [ishover, Sethover] = useState(false);
    const [isitem, Setitem] = useState(false);
    const ourProjects = [
        {
            id: 1,
            title: "Techies Park",
            color: "#DBEFFF",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/project-one.svg",
            description:
                "A technology & entrepreneurship hub. To build a strong startup ecosystem and accelerate startups, Talrop is establishing Techies Parks in all 140 Assembly constituencies of Kerala. 6 of them are already established and remaining 134 will be done by the end of 2024.",
        },
        {
            id: 2,
            title: "Startup School",
            color: "#F9F5E8",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/project-two.svg",
            description:
                "Startup School trains students from scratch and provides support to build their own startups along with giving them an opportunity to gain hands-on experience through various management tasks.",
        },
        {
            id: 3,
            title: "Startup Accelerator",
            color: "#EFEBF6",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/project-three.svg",
            description:
                "Startup Accelerator boosts early-stage startups to a highly scalable level and places them in the international market.",
        },
        {
            id: 4,
            title: "Startup Incubator",
            color: "#E2F2ED",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/project-four.svg",
            description:
                "Startup Incubator program helps aspi- rants kickstar their entrepreneurship journey by providing required support right from idea validation.",
        },
        {
            id: 5,
            title: "Media School",
            color: "#E1F3F6",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/project-five.svg",
            description:
                "Media School trains individuals with skill and interest in the media field to build a strong media team to aid Talrop's mission.",
        },
        {
            id: 6,
            title: "Technology School",
            color: "#F5EBF0",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/project-six.svg",
            description:
                "Technology School trains selected students with aptitude, to become skilled Engineers and build a strong technical team for Talrop.",
        },
    ];
    return (
        <Container>
            <section className="wrapper">
                <Head>
                    <b>Our</b> <span>Projects</span>
                </Head>
                <Description>
                    To aid the development of a strong startup ecosystem in our
                    state, Talrop has designed 6 projects
                </Description>
                <Ul>
                    {ourProjects.map((item) => (
                        <Li
                            className={item.id === isitem ? "active" : ""}
                            onMouseEnter={() => {
                                Setitem(item.id);
                            }}
                            onMouseLeave={() => {
                                Setitem("");
                            }}
                            key={item.id}
                            // data-aos="fade-up"
                        >
                            <ProjectImage
                                style={{ backgroundColor: item.color }}
                            >
                                <img src={item.image} alt="Project" />
                            </ProjectImage>
                            <Details>
                                <Title
                                    className={
                                        item.id === isitem ? "active" : ""
                                    }
                                >
                                    {item.title}
                                </Title>
                                <SubDescription
                                    className={
                                        item.id === isitem ? "active" : ""
                                    }
                                >
                                    {item.description}
                                </SubDescription>
                            </Details>
                        </Li>
                    ))}
                </Ul>
            </section>
        </Container>
    );
}
const Container = styled.section`
    padding: 75px 0;
    background-color: #f1f9ef;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
`;
const Head = styled.h3`
    font-size: 40px;
    font-family: gordita_medium;
    color: #212121;
    text-align: center;
    margin-bottom: 20px;

    & span {
        position: relative;
        z-index: 0;
    }

    & span::before {
        content: "";
        background: url(${"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/underline-green.svg"});
        background-repeat: no-repeat;
        position: absolute;
        background-size: 100%;
        width: 100%;
        left: 0;
        height: 15px;
        bottom: -10px;
        z-index: -1;
    }

    @media all and (max-width: 1400px) {
        font-size: 38px;
    }
    @media all and (max-width: 1280px) {
        font-size: 34px;
    }
    @media all and (max-width: 1080px) {
        font-size: 31px;
    }
    @media all and (max-width: 980px) {
        text-align: center;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
    }

    b {
        color: #0bbe61;
        font-family: gordita_bold;
    }
`;
const Description = styled.p`
    font-size: 18px;
    text-align: center;
    margin-bottom: 80px;
    text-align: center;
    width: 60%;
    margin: 0 auto 80px auto;
    @media all and (max-width: 980px) {
        font-size: 17px;
        width: 75%;
    }
    @media all and (max-width: 768px) {
        font-size: 16px;
        width: 85%;
    }
    @media all and (max-width: 640px) {
        width: 100%;

        & br {
            display: none;
        }
    }
`;
const Ul = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
`;
const Li = styled.div`
    width: 32%;
    margin-bottom: 70px;
    border: 1px solid #d6ecd4;
    padding: 60px 20px 20px 20px;
    min-height: 345px;
    position: relative;
    background: #fff;
    &.active {
        background: #164c4c;
        transition-delay: 0.1s;
    }
    &:nth-last-child(-n + 3) {
        margin-bottom: 0;
    }

    @media all and (max-width: 1280px) {
        min-height: 425px;
    }
    @media all and (max-width: 1080px) {
        width: 49%;
        min-height: 340px;
        &:nth-last-child(-n + 3) {
            margin-bottom: 70px;
        }
        &:nth-last-child(-n + 2) {
            margin-bottom: 0px;
        }
    }
    @media all and (max-width: 980px) {
        min-height: 360px;
    }
    @media all and (max-width: 768px) {
        min-height: 390px;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        min-height: 240px;
        margin-bottom: 50px;
        &:nth-last-child(-n + 3) {
            margin-bottom: 50px;
        }
        &:last-child {
            margin-bottom: 0px;
        }
    }
    @media all and (max-width: 480px) {
        min-height: 245px;
    }
`;
const ProjectImage = styled.div`
    margin-right: 20px;
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    padding: 15px;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 5px 11px 30px -15px rgb(119 122 119 / 98%);

    & p {
        font-family: "gordita_bold";
        font-size: 22px;
        position: absolute;
        bottom: -8px;
        right: -8px;
    }
`;
const Details = styled.div`
    font-size: 23px;
    font-family: "gordita_medium";
`;
const Title = styled.h5`
    color: #545454;
    margin-bottom: 10px;
    &.active {
        color: #fff;
        transition-delay: 0.1s;
    }
`;
const SubDescription = styled.p`
    color: #7e7e7e;
    font-size: 17px;
    &.active {
        color: #fff;
        transition-delay: 0.1s;
    }

    @media all and (max-width: 980px) {
        font-size: 16px;
    }
    @media all and (max-width: 768px) {
        font-size: 15px;
    }
`;
