import React, { useState } from "react";
import styled from "styled-components";
import FundamentBulding from "../includes/FundamentBulding";
import StrongStartup from "../includes/StrongStartup";

function StartUp() {
    const startUps = [
        {
            id: 1,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/logos/steyp.png",
        },
        {
            id: 2,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/logos/tegain-logo.png",
        },
        {
            id: 3,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/logos/dett-logo.png",
        },
        {
            id: 4,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/logos/kinfig.png",
        },
        {
            id: 5,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/logos/daistot.png",
        },
        {
            id: 6,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/grolius.png",
        },
        {
            id: 7,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/logos/wisetalkies.png",
        },
        {
            id: 8,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/18-08-2022/stickon-logo.png",
        },
        {
            id: 9,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-01-2022/Diuu.png",
        },
        {
            id: 10,
            icon: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/19-01-2022/Ykipp.png",
        },
    ];
    return (
        <Container className="wrapper" id="startup">
            <Title>
                <small>140 Startups </small> from <span>Kerala</span>{" "}
            </Title>
            <Description>
                We aim to build 140 startups from Kerala by the end of 2025 to
                shape and strengthen the startup ecosystem. We have already
                launched 10 startups and the remaining 130 will be launched in
                the upcoming years.
            </Description>
            <StartUps>
                {startUps.map((data) => (
                    <StartUpLogo key={data.id}>
                        <img src={data.icon} alt="" />
                    </StartUpLogo>
                ))}
            </StartUps>
            <StrongStartup />
            <FundamentBulding />
        </Container>
    );
}

export default StartUp;

const Container = styled.div`
    padding: 20px 0 120px;
    @media all and (max-width: 768px) {
        padding: 20px 0 100px;
    }
    @media all and (max-width: 480px) {
        padding: 20px 0 80px;
    }
    @media all and (max-width: 360px) {
        padding: 20px 0 70px;
    }
`;
const Title = styled.h2`
    font-size: 34px;
    font-family: "gordita_medium";
    text-align: center;

    small {
        font-size: 34px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 35px;
            left: 0px;
            width: 100%;
            height: 300px;
            background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/line.svg")
                no-repeat;
            background-size: contain;
            display: block;
            z-index: -1;
        }
    }
    span {
        color: #0bbe61;
    }
    @media all and (max-width: 768px) {
        font-size: 30px;
        small {
            font-size: 30px;
        }
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
        small {
            font-size: 28px;
        }
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
        small {
            font-size: 24px;
            &::before {
                top: 25px;
            }
        }
    }
    @media all and (max-width: 360px) {
        small {
            &::before {
                display: none;
            }
        }
    }
`;
const Description = styled.p`
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 16px;

    @media all and (max-width: 640px) {
        font-size: 15px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
    }
`;

const StartUps = styled.div`
    display: flex;
    justify-content: center;
    /* grid-column-gap: 50px; */

    margin: 0 auto;
    margin-top: 40px;
    max-width: 60%;
    flex-wrap: wrap;
    margin-bottom: -30px;
    @media all and (max-width: 980px) {
        /* grid-column-gap: 30px; */
        margin-top: 30px;
    }
    @media all and (max-width: 768px) {
    }
    @media all and (max-width: 360px) {
        width: 100%;
    }
`;

const StartUpLogo = styled.div`
    height: 40px;
    margin-bottom: 30px;
    padding: 0 15px;
    img {
        display: block;
        height: 100%;
    }
    @media all and (max-width: 980px) {
        height: 35px;
    }
    @media all and (max-width: 640px) {
        padding: 0 10px;
        height: 32px;
    }
    @media all and (max-width: 480px) {
        height: 30px;
    }
`;
