import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SuccessPage = () => {
  return (
    <Cover>
      <Container>
        <Top>
        <Image>
          <img
            src={
              "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/18-05-2022/checked-icon.svg"
            }
            alt="icon"
          />
          </Image>
          <Head>Successful</Head>
        </Top>
        <Center>
            <Content>Your application is successfully submitted. Our executive will 
            get back to you on a call to walk through the admission process.</Content>
            <Thank>Thank you!</Thank>
        </Center>
        <Button>
            <ButtonHome to='/projects/technology-school/'>Go to home</ButtonHome>
        </Button>
      </Container>
    </Cover>
  );
};

export default SuccessPage;
const Cover = styled.div`
  height: 100vh;
  // background:red;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  width: 55%;
//   height: 300px;
  // background:red;
  @media(max-width:640px){
    width:70%;
  }
`;
const Top = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
`;
const Image = styled.div`
    width:50px;
    @media(max-width:360px){
        width:30px;
    }
    img{
        width:100%;
        display:block;
    }
`;
const Center = styled.div`
    margin-top:20px;
`;
const Content = styled.h4`
    text-align:center;
    font-size:22px;
    color:#6B6B6B;
    width:80%;
    margin:0 auto;
    @media(max-width:980px){
        font-size:18px;
        width:90%;
    }
    @media(max-width:640px){
        font-size:18px;
        width:100%;
    }
    @media(max-width:480px){
        font-size:16px;
        // width:100%;
    }
    @media(max-width:420px){
        font-size:14px;
        // width:100%;
    }

`;
const Head = styled.h4`
    color:#003C3C;
    font-size:35px;
    margin-left:20px;
    font-family:'gordita_medium';
    @media(max-width:360px){
        font-size:25px;
    }
`;
const Thank = styled.h4`
    text-align:center;
    font-size:22px;
    margin-top:20px;
    color:#6B6B6B;
    @media(max-width:980px){
        font-size:18px;
    }
`;
const Button = styled.div``;
const ButtonHome = styled(Link)`
    width:150px;
    height:45px;
    display:flex;
    background:#0FA76F;
    margin:0 auto;
    align-items:center;
    justify-content:center;
    color:#fff;
    margin-top:30px;
    border-radius:5px;

`;