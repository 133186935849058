import React, { lazy, Suspense, useEffect } from "react";
import { Route, Router, Routes } from "react-router-dom";
import FundamentBulding from "../../includes/FundamentBulding";
import PageLoader from "../../includes/loader/PageLoader";
import ApplyForPrograms from "../../screens/ApplyForProgram";
import TalropLanding from "../../screens/TalropLanding";
import styled from "styled-components";
import SuccessPage from "../../screens/technology-landing/SuccessPage";
import NewAboutPage from "../../screens/NewAboutPage";
import NewEcosystemPage from "../../screens/NewEcosystemPage";
import NewStartupsPage from "../../screens/NewStartupsPage";
import ErrorPage from "../../screens/technology-landing/ErrorPage";
import ButtonLoader from "../../includes/loader/ButtonLoader";
import GreenLoader from "../../includes/loader/GreenLoader";
// import TechnologyLanding from "../../screens/technology-landing/TechnologyLanding";

const AppRouter = () => {
    const TechnologyLanding = lazy(() =>
        import("../../screens/technology-landing/TechnologyLanding")
    );
    const TalropLanding = lazy(() => import("../../screens/TalropLanding"));
    const TalropNewLanding = lazy(() =>
        import("../../screens/TalropNewLanding")
    );
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        // <Router>
        <Suspense
            fallback={
                <Cover>
                    <GreenLoader />
                </Cover>
            }
        >
            <Routes>
                <Route path="/" element={<TalropNewLanding />} />
                <Route path="/about" element={<NewAboutPage />} />
                <Route path="/ecosystem" element={<NewEcosystemPage />} />
                <Route path="/startups" element={<NewStartupsPage />} />
                <Route
                    path="/projects/technology-school/"
                    element={<TechnologyLanding />}
                />
                <Route path="/success/" element={<SuccessPage />} />
                <Route path="*" element={<ErrorPage />} />
                <Route path="/:id" element={<TalropNewLanding />} />
                {/* <Route path="/:*" element={<ErrorPage />} /> */}
            </Routes>
        </Suspense>
        // </Router>
    );
};

export default AppRouter;
const Cover = styled.div`
    height: 100vh;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`;
