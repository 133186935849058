import React from "react";
import styled from "styled-components";

function NewTeamBehind() {
    const Team = [
        {
            id: 1,
            name: "Safeer Najumudeen",
            designation: "Co-founder & CEO",
            linkedin: "https://www.linkedin.com/in/safeerpangode/",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/10-08-2022/Safeer-min.jpg",
        },
        {
            id: 5,
            name: "Jones Joseph",
            designation: "Director & COO",
            linkedin: "http://linkedin.com/in/jonesjoseph/",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/18-08-2022/Jones-Joseph.jpg",
        },
        {
            id: 3,
            name: "Anas Abdul Gafoor",
            designation: "Co-founder & CFO",
            linkedin: "https://www.linkedin.com/in/anas-abdul-gafoor-ab258535/",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/18-08-2022/Anas.jpg",
        },
        {
            id: 6,
            name: "Ajeesh Satheesan",
            designation: "Director & CMO",
            linkedin: "http://linkedin.com/in/ajeesh-satheesan/",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/18-08-2022/Ajeesh.jpg",
        },
        {
            id: 2,
            name: "Shameer Khan",
            designation: "Co-founder & CAO",
            linkedin: "https://www.linkedin.com/in/shameerkhanhs/",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/18-08-2022/Shameer-Khan.jpg",
        },
        {
            id: 4,
            name: "Sobir Najumudeen",
            designation: "Co-founder & CTO",
            linkedin: "https://www.linkedin.com/in/sobirpangode/",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/18-08-2022/Sobir.jpg",
        },
        {
            id: 5,
            name: "Afifa Salim",
            designation: "Director & CHRO",
            linkedin: "https://www.linkedin.com/in/afifasalim",
            image: "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/13-06-2022/Afifa+Salim.jpg",
        },
    ];
    return (
        <Container>
            <Content className="wrapper">
                <Title>
                    <b>Team</b> Behind <span>Talrop</span>
                </Title>
                <ProfileSection>
                    {Team.map((data) => (
                        <Card key={data.id}>
                            <ProfilePic>
                                <img src={data.image} alt="" />
                            </ProfilePic>
                            <Name>{data.name}</Name>
                            <Designation>{data.designation}</Designation>
                            <Linkedin href={data.linkedin} target="_blank">
                                <LinkedinIcon>
                                    <img
                                        src={
                                            "https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/29-09-2021/linkedin-white.svg"
                                        }
                                        alt=""
                                    />
                                </LinkedinIcon>
                                <p>Linkedin Profile</p>
                            </Linkedin>
                        </Card>
                    ))}
                </ProfileSection>
            </Content>
        </Container>
    );
}

export default NewTeamBehind;

const Container = styled.div`
    padding: 130px 0;
    background: #f9f9f9;
    @media all and (max-width: 768px) {
        padding: 110px 0;
    }
    @media all and (max-width: 640px) {
        padding: 100px 0;
    }
    @media all and (max-width: 480px) {
        padding: 80px 0;
        &.wrapper {
            width: 93%;
        }
    }
    @media all and (max-width: 366px) {
        &.wrapper {
            width: 96%;
        }
    }
`;
const Content = styled.div`
    @media all and (max-width: 480px) {
        &.wrapper {
            width: 93%;
        }
    }
    @media all and (max-width: 366px) {
        &.wrapper {
            width: 96%;
        }
    }
`;
const Title = styled.h2`
    font-size: 38px;
    font-family: gordita_medium;
    text-align: center;
    max-width: 500px;
    position: relative;
    margin: 0 auto;
    z-index: 9;

    b {
        color: #62af84;
        font-family: gordita_medium;
    }

    & span {
        position: relative;
    }

    & span::before {
        content: "";
        background: url(${"https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/22-08-2022/underline-green.svg"});
        background-repeat: no-repeat;
        position: absolute;
        background-size: 100%;
        width: 100%;
        left: 0;
        height: 15px;
        bottom: -10px;
        z-index: -1;
    }
    @media all and (max-width: 1280px) {
        font-size: 32px;
    }
    @media all and (max-width: 768px) {
        font-size: 30px;
    }
    @media all and (max-width: 640px) {
        font-size: 28px;
    }
    @media all and (max-width: 480px) {
        font-size: 24px;
        &::before {
            width: 200px;
            left: 20%;
            height: 150px;
        }
    }
`;

const ProfileSection = styled.div`
    width: 90%;
    margin: 0 auto;
    margin-top: 60px;
    z-index: 9;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 23px;
    @media all and (max-width: 1100px) {
        width: 100%;
    }
    @media all and (max-width: 980px) {
        gap: 26px;
    }
    @media all and (max-width: 768px) {
        gap: 30px;
    }
    @media all and (max-width: 640px) {
        width: 100%;
        grid-column-gap: 20px;
        margin-top: 50px;
    }
    @media all and (max-width: 480px) {
        gap: 23px;
        margin-top: 40px;
    }
    @media all and (max-width: 360px) {
        gap: 34px;
    }
`;

const Card = styled.div`
    width: 23.5%;
    margin-bottom: 30px;
    @media all and (max-width: 768px) {
        width: 29.5%;
    }
    @media all and (max-width: 640px) {
        width: 45.5%;
    }
    @media all and (max-width: 368px) {
        width: 46.5%;
    }
    @media all and (max-width: 360px) {
        width: 68%;
    }
`;

const ProfilePic = styled.span`
    display: block;
    padding: 15px;
    border-radius: 7px;
    box-shadow: 0px 5px 31px -7px rgb(221 226 221 / 98%);

    img {
        display: block;
        width: 100%;
        border-radius: 7px;
    }
    @media all and (max-width: 480px) {
        padding: 10px;
        border-radius: 10px;
    }
    :hover {
        border: 1px solid #d9f0e8;
    }
`;

const Name = styled.h4`
    font-size: 16px;
    font-family: gordita_medium;
    text-align: center;
    margin-top: 20px;
    @media (max-width: 480px) {
        font-size: 15px;
    }
`;
const Designation = styled.p`
    color: #747474;
    font-size: 12px;
    text-align: center;
`;
const Linkedin = styled.a`
    display: flex;
    align-items: center;
    width: 160px;
    background-color: #2b7ebc;
    height: 40px;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px auto 0;

    p {
        font-size: 12px;
        font-family: gordita_medium;
        color: #fff;
        display: flex;
        justify-content: flex-start;
        transform: translateY(1px);
    }
`;
const LinkedinIcon = styled.span`
    display: flex;
    height: 20px;
    width: 25px;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #fff;
    padding-right: 6px;
    margin-right: 6px;
    img {
        display: block;
        width: 15px;
        transform: translateY(-3px);
    }
`;
