import React from "react";
import styled from "styled-components";
import Header from "../includes/Header";

const SpotLight = () => {
    return (
        <Conatiner id="spotlight">
            <BackContainer>
                <Header />
                <Cover className="wrapper">
                    <LeftSection>
                        <Title>
                            Building a strong <span>Startup Ecosystem</span> in Kerala
                        </Title>
                        <Description>
                            Talrop is building a strong startup ecosystem in Kerala. We mould
                            entrepreneurs and build startups from scratch and take them to a highly
                            scalable level, thereby creating more job opportunities and facilitating
                            remarkable economic growth in our state.
                        </Description>
                    </LeftSection>
                </Cover>
            </BackContainer>
        </Conatiner>
    );
};

export default SpotLight;

const Conatiner = styled.div`
    background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/20-10-2021/spotlight-bg.png")
        no-repeat;
    background-size: cover;
    background-position: center bottom;
    position: relative;
    @media all and (max-width: 1280px) {
    }
`;
const BackContainer = styled.div`
    background: url("https://s3.ap-south-1.amazonaws.com/talrop.com-react-assets-bucket/assets/images/21-10-2021/spotlight.png")
        no-repeat;
    background-size: contain;
    background-position: top right;
    @media all and (max-width: 1440px) {
        background-size: 48%;
    }
    @media all and (max-width: 480px) {
        background-size: 63%;
    }
`;
const Cover = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media all and (max-width: 1280px) {
        grid-template-columns: 1fr;
    }
`;
const LeftSection = styled.div`
    max-width: 90%;
    padding: 300px 0 200px;
    @media all and (max-width: 1280px) {
        padding: 300px 0 150px;
    }
    @media all and (max-width: 768px) {
        padding: 250px 0 120px;
    }
    @media all and (max-width: 640px) {
        padding: 250px 0 100px;
    }
    @media all and (max-width: 640px) {
        padding: 250px 0 80px;
    }
    @media all and (max-width: 480px) {
        max-width: 100%;
    }
    @media all and (max-width: 360px) {
        padding: 180px 0 70px;
    }
`;
const RightSection = styled.div`
    margin-right: -18%;
    img {
        display: block;
        width: 100%;
    }
    @media all and (max-width: 1280px) {
        position: absolute;
        width: 50%;
        top: 0;
        right: 0;
        margin-right: 0;
    }
    @media all and (max-width: 980px) {
        width: 55%;
    }
    @media all and (max-width: 768px) {
        width: 50%;
    }
    @media all and (max-width: 480px) {
        width: 60%;
    }
    @media all and (max-width: 360px) {
        width: 55%;
    }
`;
const Title = styled.h1`
    font-size: 55px;
    font-family: gordita_medium;
    @media all and (max-width: 1400px) {
        font-size: 50px;
    }
    @media all and (max-width: 1280px) {
        max-width: 500px;
        font-size: 48px;
    }
    @media all and (max-width: 768px) {
        font-size: 44px;
    }
    @media all and (max-width: 640px) {
        font-size: 36px;
    }
    @media all and (max-width: 480px) {
        font-size: 28px;
    }
    span {
        display: block;
        color: #0bbe61;
        font-family: gordita_bold;
    }
`;
const Description = styled.p`
    margin-top: 20px;
    font-size: 18px;
    @media all and (max-width: 1280px) {
        max-width: 600px;
    }
    @media all and (max-width: 980px) {
        font-size: 16px;
    }
    @media all and (max-width: 480px) {
        font-size: 14px;
        margin-top: 15px;
    }
`;
